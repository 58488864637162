export function useDataLayer() {
  if (!window['dataLayer']) {
    window['dataLayer'] = [];
  }

  return {
    push(...args: any[]): void {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        args.forEach(item => console.log(JSON.stringify(item, null, 2)));
      }

      window['dataLayer'].push(...args);
    },
  };
}
