import React from 'react';
import styles from './MasonryGrid.module.scss';
import { Article } from '../../models/article';
import { LoadMore } from '../load-more/LoadMore';
import { useDataLayer } from '../lib/hooks/useDataLayer';

interface MasonryGridProps {
  rows?: Array<Article[]>;
  onNeedsMore?: () => void;
  showButton?: boolean;
}

const keys = ['one', 'two', 'three', 'four', 'five', 'six'];

export function MasonryGrid(props: MasonryGridProps): JSX.Element {
  const { push } = useDataLayer();

  return (
    <div className="gridWrapper">
      {!!props.rows &&
        props.rows.map((item, index) => {
          return (
            <div key={index}>
              <div className={styles.grid}>
                {!!item?.length &&
                  item.map((card, key) => {
                    return (
                      <a
                        href={card.readMoreLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        key={key + '' + index}
                        onClick={() => {
                          push({
                            event: 'READ_ARTICLE',
                            eventRef: `${card.title} | ${card.author}`,
                          });
                        }}
                        className={`${styles.block} ${styles[keys[key]]}`}
                      >
                        <div className={styles.card}>
                          <div className={styles.image}>
                            <img
                              loading="lazy"
                              src={card.thumbnailImage}
                              alt="hello"
                            />
                          </div>
                          <div className={styles.content}>
                            <h5>{card.title}</h5>
                            <p className={`${styles.contentSnippet} text-left`}>
                              {card.snippet}
                            </p>
                            <p>
                              By <strong>{card.author}</strong>
                            </p>
                            <p className={styles.contentDate}>
                              {new Date(card.publishDate).toLocaleDateString(
                                'en-ZA',
                                {
                                  month: 'long',
                                  day: 'numeric',
                                  year: 'numeric',
                                }
                              )}
                            </p>
                          </div>
                        </div>
                      </a>
                    );
                  })}
              </div>
              {index + 1 === props.rows.length && props.showButton && (
                <div className="button-wrapper">
                  <LoadMore
                    onClick={() => {
                      props.onNeedsMore();

                      push({
                        event: 'VIEW_MORE_ARTICLES',
                      });
                    }}
                  />
                </div>
              )}

              {/* {!props.showButton && index + 1 === props.rows.length && (
                
              )} */}
            </div>
          );
        })}
    </div>
  );
}
