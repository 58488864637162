import React, { useState, useEffect } from 'react';
import styles from './ContactWizard.module.scss';
import { ShowWhenReact } from '../lib/utils/showOnReact';
import { Button } from '../button/Button';
import ball from '../../assets/ball.svg';
import { InputField } from '../input-field/InputField';
import { useForm } from 'react-hook-form';
import { Checkbox } from '../checkbox/Checkbox';
import { Textarea } from '../textarea/Textarea';
import { RadioButton } from '../radio-button/RadioButton';
import { Validators } from './validators';
import tri from '../../assets/triangles.svg';
import { randomEmoji } from '../lib/utils/randomEmoji';
import { ContactLead } from '../../models/contact-leads';
import { useDataLayer } from '../lib/hooks/useDataLayer';

interface State {
  activeStep: string | null;
  previousStep: string | null;
}

const defaultState: State = {
  activeStep: 'startStep',
  previousStep: null,
};

// In order to have the "get the ball rolling"
// transition, the first step (what's your name)
// has a transition delay. However, navigating
// backwards causes issues. Here we mark when the
// first step has been completed so that we can
// check for usage in the component.
let stepOneDone: boolean = false;

const map = {
  vp: 'Value propositioning',
  sp: 'Solution prototyping',
  ut: 'Usability testing',
  wm: 'Web or mobile app',
  all: 'Broad-scale CX design',
  other: 'A Custom Solution',
};

export function ContactWizard(props?: {
  onSubmit?: (value: ContactLead) => void;
}): JSX.Element {
  // Registers the needed controls for a form. This
  // is the backbone of this component
  const { register, triggerValidation, errors, getValues, setValue } = useForm({
    reValidateMode: 'onChange',
  });

  const { push } = useDataLayer();

  // This is where we keep track of the state of
  // the form and its component.
  const [state, setState] = useState<State>(defaultState);

  // Here we keep track of the how the user
  // wants to be contacted
  const [contactType, setContactType] = useState<string | undefined>('email');

  // Here we register an event listener that
  // will listen for when the enter key is
  // pressed and go to the next step.
  useEffect(() => {
    const handler = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        switch (state.activeStep) {
          case 'startStep':
            stepOneDone = true;
            return goTo('yourName')();
          case 'yourName':
            return goTo('whatYouWant')();
          case 'whatYouWant':
            return goWhatYouWant();
          case 'whatYouWantContd':
            return goTo('whatIndustry')();
          case 'whatIndustry':
            return goTo('contactInfo')();
          case 'contactInfo':
            return send();
        }
      }
    };

    window.addEventListener('keyup', handler);

    return () => window.removeEventListener('keyup', handler);
  });

  // This function fires when the value of a
  // checkbox changes. If the value of the
  // checkbox is "other", we delselct all of the
  // other checkboxes. If the value of the
  // checkbox is not "other", we deselect the
  // "other" checkbox.
  function handleChechboxLogic(value: string): void {
    const values: string[] = getValues()?.whatYouWant;

    if (value === 'other' && values.includes('other')) {
      setValue('whatYouWant', ['other']);
    } else if (value !== 'other') {
      setValue(
        'whatYouWant',
        values.filter(v => v !== 'other')
      );
    }

    revalidate('whatYouWant')();
  }

  // This function returns a list of classes
  // based on the given step name and what
  // the currently active step is.
  function getStepClasses(name: string): string {
    const { activeStep, previousStep } = state;

    const nameClass = styles[name];

    let join = ' ';
    if (name === activeStep) join = ` ${styles.stepIn} `;
    if (name === previousStep) join = ` ${styles.stepOut} `;

    return `${styles.step}${join}${nameClass}`;
  }

  // This function is used to navigate between
  // steps. It will return a function that must
  // be fired to actually perform the navigation
  function goTo(step: string, ...validateFields: string[]): () => void {
    return () => {
      const { activeStep, previousStep } = state;

      if (step === activeStep) return;

      const validates = validateFields.length ? validateFields : activeStep;

      if (!previousStep) {
        push({ event: 'BEGIN_CONTACT_FORM' });
        setState({ activeStep: step, previousStep: activeStep });
      } else {
        triggerValidation(validates || undefined).then(isValid => {
          if (isValid) {
            setState({ activeStep: step, previousStep: activeStep });
          }
        });
      }
    };
  }

  // Checks if the user selected one of the solutions
  // provided or if they would prefer to just describe
  // what they need and navigates between steps.
  function goWhatYouWant(): void {
    const values: string[] = getValues()?.whatYouWant;

    if (values.includes('other') || !values.length) {
      goTo('whatYouWantContd')();
    } else goTo('whatIndustry')();
  }

  // Based on how the user wants to be contacted, we
  // navigate to the final step with the needed field
  // validations
  function goContact(): void {
    if (contactType === 'email') {
      goTo('endStep', 'contactInfoEmail')();
    } else if (contactType === 'phone') {
      goTo('endStep', 'contactInfoPhone', 'contactInfoPhoneCode')();
    } else if (contactType === 'both') {
      goTo(
        'endStep',
        'contactInfoEmail',
        'contactInfoPhone',
        'contactInfoPhoneCode'
      )();
    }
  }

  // This function will be fired when the form needs
  // to be submitted.
  function send(): void {
    const value: ContactLead = JSON.parse(JSON.stringify(getValues()));

    push({
      event: 'SUBMIT_CONTACT_FORM',
      date: new Date().toISOString(),
      name: value.yourName,
      fromPage: window.location.pathname,
    });

    value.whatYouWant = value.whatYouWant.map(key => {
      return map[key];
    });

    props?.onSubmit({
      ...value,
      entryPoint: window.location.pathname,
      formSubmitDate: new Date(),
    });

    return goContact();
  }

  // Revalidates a field using the "react-hook-form"
  // function given at the top of this component
  function revalidate(name: string) {
    return () => triggerValidation(name).then(() => {});
  }

  // This function can be used to navigate backwards
  // in the form.
  function back(): void {
    switch (state.activeStep) {
      case 'whatYouWant':
        return setState({
          activeStep: 'yourName',
          previousStep: state.activeStep,
        });
      case 'whatYouWantContd':
        return setState({
          activeStep: 'whatYouWant',
          previousStep: state.activeStep,
        });
      case 'whatIndustry':
        return setState({
          activeStep: 'whatYouWant',
          previousStep: state.activeStep,
        });
      case 'contactInfo':
        return setState({
          activeStep: 'whatIndustry',
          previousStep: state.activeStep,
        });
    }
  }

  function ProgressBar({ step = 1 }: { step: number }): JSX.Element {
    return (
      <div className={styles.formProgressBar}>
        <ul className={styles.formProgressBarInner}>
          <li
            onClick={goTo('yourName')}
            className={`${styles.formProgressStep} ${
              step >= 1 && styles.stepIsActive
            }`}
          ></li>
          <li
            onClick={goTo('whatYouWant')}
            className={`${styles.formProgressStep} ${
              step >= 2 && styles.stepIsActive
            }`}
          ></li>
          <li
            onClick={goTo('whatIndustry')}
            className={`${styles.formProgressStep} ${
              step >= 3 && styles.stepIsActive
            }`}
          ></li>
          <li
            onClick={goTo('contactInfo')}
            className={`${styles.formProgressStep} ${
              step >= 4 && styles.stepIsActive
            }`}
          ></li>
        </ul>
      </div>
    );
  }

  function renderFinalMessage(): JSX.Element | undefined {
    if (contactType === 'both' || contactType === 'email') {
      return (
        <p className="display text-center">
          We’ll be in touch via email to discuss your solution.
        </p>
      );
    } else if (contactType === 'phone') {
      return (
        <p className="display text-center">
          We’ll give you a call to discuss your solution.
          <br />
          Chat soon!
        </p>
      );
    }
  }

  return (
    <ShowWhenReact>
      <section
        data-contact-form
        className={`${styles.contactFormWizard} padding-block-end-6`}
      >
        <div className={`${styles.formWrapper} container`}>
          <div className={`${getStepClasses('startStep')}`}>
            <div className={styles.inner}>
              <h2 className="h1 text-center">
                Already got an idea of what you need?
              </h2>
              <br />
              <Button onClick={goTo('yourName')}>GET THE BALL ROLLING</Button>
            </div>

            <div className={styles.ball}>
              <object data={ball} type="image/svg+xml">
                <img loading="lazy" src={ball} alt="the ball" />
              </object>
            </div>
          </div>

          <div
            className={`${getStepClasses('yourName')} ${
              stepOneDone ? styles.stepOneDone : ''
            }`}
          >
            <div className={styles.formInner}>
              <div className={styles.stepTitle}>
                <h4 className="padding-block-end-4">
                  Let’s start with the introductions.{' '}
                  <strong>We’re Phiresky.</strong>
                </h4>
              </div>
              <div className={styles.formInputWrapper}>
                <InputField
                  inputRef={register(Validators.yourName)}
                  errors={errors}
                  name="yourName"
                  placeholder="What's your name?"
                  onInput={revalidate('yourName')}
                ></InputField>
              </div>

              <div className={styles.formFooter}>
                <ProgressBar step={1} />
                <div className={styles.formButtons}>
                  <div className={styles.buttons}>
                    <Button
                      onClick={() => {
                        goTo('whatYouWant')();
                        stepOneDone = true;
                      }}
                    >
                      NEXT
                    </Button>
                    <br />
                    <p>or press ENTER</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={`${getStepClasses('whatYouWant')}`}>
            <div className={styles.formInner}>
              <div className={styles.stepTitle}>
                <h4>
                  Hi<strong>{' ' + getValues()?.yourName ?? ''}</strong>,
                </h4>
                <h4 className={styles.blueLabel}>What do you need?</h4>
                <p>(you can select more than one)</p>
              </div>
              <div className={styles.formInputWrapper}>
                <ul className={styles.checkList}>
                  <li className={styles.checkItem}>
                    <Checkbox
                      errors={errors}
                      checkType="tick"
                      smaller={true}
                      inputRef={register({ required: true })}
                      value="vp"
                      name="whatYouWant"
                      onChecked={() => handleChechboxLogic('vp')}
                      placeholder="Value propositioning"
                    />
                  </li>
                  <li className={styles.checkItem}>
                    <Checkbox
                      errors={errors}
                      checkType="tick"
                      smaller={true}
                      inputRef={register({ required: true })}
                      value="sp"
                      name="whatYouWant"
                      onChecked={() => handleChechboxLogic('sp')}
                      placeholder="Solution Prototyping"
                    />
                  </li>
                  <li className={styles.checkItem}>
                    <Checkbox
                      errors={errors}
                      checkType="tick"
                      smaller={true}
                      inputRef={register({ required: true })}
                      value="ut"
                      name="whatYouWant"
                      onChecked={() => handleChechboxLogic('ut')}
                      placeholder="Usability testing"
                    />
                  </li>
                  <li className={styles.checkItem}>
                    <Checkbox
                      errors={errors}
                      checkType="tick"
                      smaller={true}
                      inputRef={register({ required: true })}
                      value="wm"
                      name="whatYouWant"
                      onChecked={() => handleChechboxLogic('wm')}
                      placeholder="Web or mobile app"
                    />
                  </li>
                  <li className={styles.checkItem}>
                    <Checkbox
                      errors={errors}
                      checkType="tick"
                      smaller={true}
                      inputRef={register({ required: true })}
                      value="all"
                      name="whatYouWant"
                      onChecked={() => handleChechboxLogic('all')}
                      placeholder="Broad-scale CX design"
                    />
                  </li>
                  <li className={styles.checkItem}>
                    <Checkbox
                      errors={errors}
                      checkType="tick"
                      smaller={true}
                      bold={true}
                      theme="yellow"
                      inputRef={register({ required: true })}
                      value="other"
                      name="whatYouWant"
                      onChecked={() => handleChechboxLogic('other')}
                      placeholder="I'd rather describe it to you"
                    />
                  </li>
                </ul>
                {!!errors.whatYouWant && (
                  <span className={styles.contactError}>
                    To continue, select at least one option{' '}
                    <span role="img" aria-label="Smug">
                      😌
                    </span>
                  </span>
                )}
              </div>

              <div className={styles.formFooter}>
                <ProgressBar step={2} />
                <div className={styles.formButtons}>
                  <div className={styles.buttons}>
                    <Button onClick={() => goWhatYouWant()}>NEXT</Button>
                    <br />
                    <p>or press ENTER</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={`${getStepClasses('whatYouWantContd')}`}>
            <div className={styles.formInner}>
              <div className={styles.stepTitle}>
                <h4
                  className={`${styles.blueLabel} padding-block-end-4 padding-block-start-0`}
                >
                  Tell us what you need in a nutshell:
                </h4>
                <p>(a 300-character nutshell)</p>
              </div>
              <div className={styles.formInputWrapper}>
                <Textarea
                  inputRef={register(Validators.solutionDetails)}
                  errors={errors}
                  name="whatYouWantContd"
                  maxLength={300}
                  limitText="Say no more :) We’ll discuss it in detail later."
                />
              </div>

              <div className={styles.formFooter}>
                <ProgressBar step={2} />
                <div className={styles.formButtons}>
                  <div className={styles.buttons}>
                    <Button onClick={goTo('whatIndustry')}>NEXT</Button>
                    <br />
                    <p>or press ENTER</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={`${getStepClasses('whatIndustry')}`}>
            <div className={styles.formInner}>
              <div className={styles.stepTitle}>
                <h4 className={`padding-block-end-2`}>
                  And what industry would your solution fall under?
                </h4>
              </div>
              <div className={styles.formInputWrapper}>
                <InputField
                  inputRef={register}
                  errors={errors}
                  name="whatIndustry"
                  placeholder="What idustry are you in?"
                  hidePlaceholder={true}
                />
              </div>

              <div className={styles.formFooter}>
                <ProgressBar step={3} />
                <div className={styles.formButtons}>
                  <div className={styles.buttons}>
                    <Button onClick={goTo('contactInfo')}>NEXT</Button>
                    <br />
                    <p>or press ENTER</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={`${getStepClasses('contactInfo')}`}>
            <div className={styles.formInner}>
              <div className={styles.stepTitle}>
                <h4 className="padding-block-end-4">Sounds interesting!</h4>
                <h4 className={styles.blueLabel}>How should we contact you?</h4>
              </div>

              <div className={styles.formInputWrapper}>
                <div className="radioButtons">
                  <RadioButton
                    checked={contactType === 'email'}
                    name="contactType"
                    value="email"
                    placeholder="by email"
                    onChecked={value => setContactType(value)}
                  />

                  <RadioButton
                    checked={contactType === 'phone'}
                    name="contactType"
                    value="phone"
                    placeholder="by phone"
                    onChecked={value => setContactType(value)}
                  />

                  <RadioButton
                    checked={contactType === 'both'}
                    name="contactType"
                    value="both"
                    placeholder="both"
                    theme="yellow"
                    onChecked={value => setContactType(value)}
                  />
                </div>
                {contactType && (
                  <div className="contactInputs">
                    {(contactType === 'email' || contactType === 'both') && (
                      <div className={styles.phoneInput}>
                        <InputField
                          name="contactInfoEmail"
                          type="email"
                          errors={errors}
                          placeholder="Email"
                          inputRef={register(Validators.email)}
                          onInput={revalidate('contactInfoEmail')}
                        />
                      </div>
                    )}

                    {(contactType === 'phone' || contactType === 'both') && (
                      <div className={styles.phoneInput}>
                        <div className={styles.country}>
                          <div className={styles.codeIcon}>+</div>
                          <InputField
                            name="contactInfoPhoneCode"
                            type="number"
                            pattern="[0-9]+"
                            placeholder="Dialling code"
                            errors={errors}
                            inputRef={register(Validators.dialingCode)}
                            onInput={revalidate('contactInfoPhoneCode')}
                          />
                        </div>
                        <div className={styles.value}>
                          <InputField
                            name="contactInfoPhone"
                            type="number"
                            errors={errors}
                            placeholder="Phone number"
                            inputRef={register(Validators.phone)}
                            onInput={revalidate('contactInfoPhone')}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>

              <div className={styles.formFooter}>
                <ProgressBar step={4} />
                <div className={styles.formButtons}>
                  <div className={styles.buttons}>
                    <Button onClick={send}>SEND</Button>
                    <br />
                    <p>or press ENTER</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={`${getStepClasses('endStep')}`}>
            <div className={styles.formInner}>
              <div className="text-center">
                <h1 className="text-center">
                  <strong>
                    Message received,
                    <br />
                    {getValues()?.yourName} {randomEmoji()}
                  </strong>
                </h1>
                <br />
                <div className="container">{renderFinalMessage()}</div>
                <br />
                {/* <ScrollDownArrow label="Scroll Down Now" theme="orange" /> */}
              </div>
            </div>
          </div>

          <div className={styles.formTriangles}>
            <img loading="lazy" src={tri} alt="triangles" />
          </div>

          {state.activeStep !== 'startStep' &&
            state.activeStep !== 'yourName' &&
            state.activeStep !== 'endStep' && (
              <div onClick={back} className={styles.backButton}>
                <span></span> back
              </div>
            )}
        </div>
      </section>
    </ShowWhenReact>
  );
}
