import { useState, useEffect } from 'react';

function useMediaQuery(query: MediaQueryList | string): boolean {
  let mediaQuery: MediaQueryList;
  if (typeof query === 'string') {
    mediaQuery = window.matchMedia(query);
  } else mediaQuery = query;

  const [matches, set] = useState(mediaQuery.matches);

  useEffect(() => {
    const handler = () => set(mediaQuery.matches);
    mediaQuery.onchange = handler;

    return () => {
      mediaQuery.onchange = null;
    };
  });

  return matches;
}

export { useMediaQuery };
