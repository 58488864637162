import React, { useLayoutEffect } from 'react';
import styles from './InformationPanel.module.scss';
import { useMediaQuery } from '../lib/utils/useMediaQuery';
import { Link } from 'react-router-dom';
import apple from '../../assets/buttons/apple.png';
import google from '../../assets/buttons/google.png';
import { useDataLayer } from '../lib/hooks/useDataLayer';

interface InformationPanelProps {
  company?: string;
  year: number;
  included: string;
  website?: string;
  play?: string;
  app?: string;
  slug: string;
  id: string;
}

const $ = `ul.${styles.informationList}`;

export function InformationPanel(props: InformationPanelProps): JSX.Element {
  const isTablet = useMediaQuery('screen and (max-width: 768px)');
  const { push } = useDataLayer();

  useLayoutEffect(() => {
    const item = document.querySelector($) as HTMLElement;
    const parent = item.parentElement as HTMLElement;

    if (isTablet) return item.setAttribute('data-float', `false`);

    const handler = () => {
      const { top, left } = parent.getBoundingClientRect();
      item.style.left = `${left}px`;
      item.setAttribute('data-float', `${top <= 70}`);
    };

    window.addEventListener('scroll', handler);

    return () => window.removeEventListener('scroll', handler);
  });

  return (
    <ul className={styles.informationList}>
      {props.company && (
        <li className={styles.informationItem}>
          <strong>Company</strong>
          <span>{props.company}</span>
        </li>
      )}
      <li className={styles.informationItem}>
        <strong>Year</strong>
        <span>{props.year}</span>
      </li>
      <li className={styles.informationItem}>
        <strong>Project included</strong>
        <span>{props.included}</span>
      </li>
      {props.website && (
        <li className={styles.informationItem}>
          <a
            className="with-arrow yellow"
            target="_blank"
            rel="noreferrer noopener"
            href={props.website}
            onClick={() => {
              push({
                event: 'VIEW_SOURCE_WEBSITE',
                eventRef: `${props.slug} | ${props.id}`,
              });
            }}
          >
            Website
          </a>
        </li>
      )}
      {props.play && (
        <li className={styles.informationItem}>
          <a
            href={props.play}
            target="_blank"
            rel="noreferrer noopener"
            onClick={() => {
              push({
                event: 'VIEW_SOURCE_PLAYSTORE',
                eventRef: `${props.slug} | ${props.id}`,
              });
            }}
          >
            <img src={google} alt="Get on the play store" />
          </a>
        </li>
      )}
      {props.app && (
        <li className={styles.informationItem}>
          <a
            href={props.app}
            target="_blank"
            rel="noreferrer noopener"
            onClick={() => {
              push({
                event: 'VIEW_SOURCE_APPSTORE',
                eventRef: `${props.slug} | ${props.id}`,
              });
            }}
          >
            <img src={apple} alt="Get on the app store" />
          </a>
        </li>
      )}

      <li className={styles.informationItem}>
        <Link className="with-arrow reverse" to="/work">
          Case Studies
        </Link>
      </li>
    </ul>
  );
}
