import React, { useState } from 'react';
import styles from './ScrollDownArrow.module.scss';

interface Props {
  anchor?: HTMLElement;
  label: string;
  theme?: 'blue' | 'yellow' | 'orange';
}

export function ScrollDownArrow(props: Props): JSX.Element {
  const [id] = useState(
    Date.now().toString(32) +
      Math.random()
        .toString(32)
        .replace('.', '')
  );

  function scroll(anchor?: HTMLElement): void {
    if (!anchor) {
      anchor = document.getElementById(id) as HTMLElement;
    }

    anchor.scrollIntoView({ behavior: 'smooth' });
  }

  return (
    <section
      id={id}
      className={`${styles.scrollArrowWrapper} ${
        styles[`theme${props.theme || 'blue'}`]
      } padding-block-5`}
      onClick={scroll.bind({}, props.anchor)}
    >
      <div className={`${styles.scrollLabel} text-center margin-block-end-4`}>
        <p>{props.label}</p>
      </div>

      <div className={`${styles.scrollArrow} margin-inline-auto`}>
        <svg fill="none" viewBox="0 0 24 14">
          <path
            stroke="#4084FF"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="4"
            d="M22 2L12 12 2 2"
          />
        </svg>
      </div>
    </section>
  );
}
