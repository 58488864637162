import React from 'react';
import styles from './GoogleMap.module.scss';

export function GoogleMap(): JSX.Element {
  return (
    <a
      className={styles.map}
      target="_blank"
      rel="noopener noreferrer"
      href="https://www.google.com/maps/place/Phiresky+Consulting,+Northridge+Court,+De+Wet+St,+Northcliff,+Johannesburg,+2195/"
    >
      <img
        loading="lazy"
        src="https://maps.googleapis.com/maps/api/staticmap?center=Phiresky+Consulting,+Northridge+Court,+De+Wet+St,+Northcliff,+Johannesburg,+2195&zoom=13&scale=2&size=500x500&maptype=roadmap&key=AIzaSyCKzboKK7s7IVy-hG4n2WBSei8snRhtWTA&format=jpg&visual_refresh=true&markers=size:mid%7Ccolor:0x4185ff%7Clabel:%7CPhiresky+Consulting,+Northridge+Court,+De+Wet+St,+Northcliff,+Johannesburg,+2195"
        alt="Google Map of Phiresky Consulting, Northridge Court, De Wet St, Northcliff, Johannesburg, 2195"
      />
    </a>
  );
}
