import React from 'react';
import styles from './CaseStudyGallery.module.scss';
import { GalleryImages } from '../../models/case-study';
import { chunk } from '../lib/utils/chunk';

interface CaseStudyProps {
  images: GalleryImages[];
}

type GalleryChunk = Array<[GalleryImages, GalleryImages, GalleryImages]>;

function LaunchPhotoTile(src: string) {
  console.log(src);
  const image = new Image();

  const overlay = document.createElement('div');
  overlay.classList.add('image-overlay');
  overlay.innerHTML = '<span></span>';
  document.body.appendChild(overlay);
  overlay.onclick = () => overlay.remove();

  image.onload = () => {
    overlay.innerHTML = '';
    overlay.appendChild(image);
  };

  image.src = src;
}

export function CaseStudyGallery(props: CaseStudyProps): JSX.Element {
  const chunked: GalleryChunk = chunk(props.images, 3) as any;

  return (
    <div className={styles.galleryWrapper}>
      <br />
      <h1>Gallery Images</h1>
      <div className={styles.imageInner}>
        {chunked.map((row, r) => {
          return (
            <div
              key={r}
              className={`${styles.galleryRow} ${
                row?.length < 2 ? styles.noGrid : ''
              }`}
            >
              {row?.length &&
                row.map((item, i) => {
                  return (
                    <div
                      className={styles.photoItem}
                      key={i + '' + r}
                      onClick={() => LaunchPhotoTile(item.imageUrl)}
                    >
                      <img src={item.imageUrl} alt={item.caption} />

                      <div className={styles.captions}>
                        <h5 className="greyed">{item.caption}</h5>
                      </div>
                    </div>
                  );
                })}
            </div>
          );
        })}
      </div>
    </div>
  );
}
