import React, { Component, KeyboardEvent, PropsWithChildren } from 'react';
import styles from './InputField.module.scss';
import { NestDataObject, ErrorMessage } from 'react-hook-form';
import { hasModifierKey, hasKeycode } from './helpers';
import { InputRef } from '../../models/InputRef';

type InputType = 'text' | 'number' | 'email' | 'tel' | 'search' | 'password';

interface InputProps {
  name: string;
  placeholder: string;
  hidePlaceholder?: boolean;
  type?: InputType;
  inputMode?: 'none' | 'text' | 'decimal' | 'numeric';
  pattern?: string;
  errors?: NestDataObject<Record<string, any>>;
  inputRef?: InputRef<HTMLInputElement>;
  onInput?: ((event: React.FormEvent<HTMLInputElement>) => void) | undefined;
  leadHint?: string;
}

const ALLOW_ON_NUMBER_FIELDS = [
  9, // tab
  37, // left arrow
  39, // right arrow
  13, // enter
  8, // backspace
];

export class InputField extends Component<InputProps> {
  constructor(props: PropsWithChildren<InputProps>) {
    super(props);
    this.handleInputTyping = this.handleInputTyping.bind(this);
  }

  public handleInputTyping(event: KeyboardEvent<HTMLInputElement>): void {
    if (this.props.type === 'number' || this.props.type === 'tel') {
      const valueisnumber = /[0-9]/gi.test(event.key);

      if (
        !hasModifierKey(event) &&
        !valueisnumber &&
        !hasKeycode(event, ...ALLOW_ON_NUMBER_FIELDS)
      ) {
        event.preventDefault();
      }
    }
  }

  public render(): JSX.Element {
    const {
      name,
      placeholder,
      type = 'text',
      inputRef,
      errors,
      hidePlaceholder,
      // leadHint,
    } = this.props;

    return (
      <div
        className={`${styles.inputWrapper} ${
          !!errors && !!errors[name] ? styles.fieldHasErrors : ''
        } padding-block-end-1`}
      >
        {/* {!!leadHint && <div className={styles.icon}>{leadHint}</div>} */}
        <input
          pattern={this.props.pattern}
          inputMode={this.props.inputMode || 'text'}
          placeholder={placeholder}
          type={type}
          ref={inputRef}
          id={name}
          name={name}
          value={this.props.leadHint}
          onKeyDown={this.handleInputTyping}
          onInput={this.props.onInput}
        />
        {!hidePlaceholder && <label htmlFor={name}>{placeholder}</label>}
        <div className={`${styles.inputUnderline}`}></div>
        {!!errors && (
          <ErrorMessage
            className={styles.inputErrorMessage}
            errors={errors}
            name={name}
            as="div"
          />
        )}
      </div>
    );
  }
}
