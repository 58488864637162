import React from 'react';
import { useParams, Redirect, useLocation } from 'react-router-dom';
import { CookiePolicy } from './CookiePolicy.partial';
import { ManageCookiePolicy } from './ManageCookies.partials';
import { PrivacyPolicy } from './PrivacyPolicy.partial';
import { TermsConditions } from './TermsConditions.partial';

export function LegalPages(): JSX.Element {
  const { page } = useParams<any>();
  const { pathname } = useLocation();

  switch (page) {
    case 'cookie-policy':
      return <CookiePolicy />;
    case 'privacy-policy':
      return <PrivacyPolicy />;
    case 'terms-and-conditions':
      return <TermsConditions />;
    case 'cookies-manage':
      return <ManageCookiePolicy />;
    default:
      return <Redirect to={`/page-not-found?url=${pathname}`} />;
  }
}
