import React from 'react';
import { useLocation } from 'react-router-dom';
import Helmet from 'react-helmet';

const pageStyles: React.CSSProperties = {
  width: '100%',
  height: 'calc(100vh - 100px)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const innerStyles: React.CSSProperties = {
  maxWidth: '700px',
  width: '100%',
};

const textStyles: React.CSSProperties = {
  textAlign: 'center',
};

export function NotFound(): JSX.Element {
  const { pathname, search } = useLocation();
  const params = new URLSearchParams(search);

  return (
    <main className={`home`} style={pageStyles}>
      <Helmet>
        <title>
          404 Page not found | page {params.get('url') || pathname} doesn't
          exsist
        </title>
      </Helmet>
      <div style={innerStyles}>
        <h1 style={textStyles}>404 Page Not Found</h1>
        <hr />
        <h4 style={textStyles}>
          Page at url <strong>{params.get('url') || pathname}</strong> could not
          be found
        </h4>
      </div>
    </main>
  );
}
