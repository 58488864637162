import { ContactLead } from '../../../models/contact-leads';
import emailjs from '@emailjs/browser';
export function useContactForm() {
  return {
    async submitForm(value: ContactLead) {
      await sendForm(value);
      return value;
    },
  };
}

function sendForm(lead: any): Promise<void> {
  console.log(JSON.stringify(lead, null, 2));


  return emailjs.send('service_ekoei8p', 'template_wvhndj9', lead,'8nhUEqmX81Z-6q0Xv')
    .then(function(response) {
       console.log('SUCCESS!', response.status, response.text);
    }, function(error) {
       console.log('FAILED...', error);
    });

}
