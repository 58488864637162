import React from 'react';
import styles from './Components.module.scss';
import { Button } from '../../components/button/Button';
import { addConfetti } from '../../components/lib/confetti/confetti';
import {
  ScrollDownArrow,
  LargeArticleCard,
  InputField,
  GoogleMap,
  ArticleHeader,
  Checkbox,
  RadioButton,
  Dropdown,
} from '../../components';
import { useForm } from 'react-hook-form';
import header from '../../assets/header_2.jpg';
import artle from '../../assets/placeholder/D_CaseStudyHeader_BalloonPayments.svg';
import validators from '../../components/lib/validations/validators';

export function Components(): JSX.Element {
  const { register, handleSubmit, errors } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  return (
    <div className={`page ${styles.componentsPage} margin-inline-auto`}>
      <article className="padding-block-6">
        <section id="buttons">
          <h2 className="text-center">Buttons</h2>
          <hr />
          <div className="margin-block-end-5">
            <Button as="a" href="/components">
              Yellow Button
            </Button>
          </div>

          <div className="margin-block-end-5">
            <Button theme="blue">Blue Button</Button>
          </div>

          <div className="margin-block-end-5">
            <Button theme="navy">Navy Button</Button>
          </div>

          <div className="margin-block-end-5">
            <Button theme="dark">Dark Button</Button>
          </div>

          <div>
            <Button theme="light">Light Button</Button>
          </div>
        </section>

        <section id="typography">
          <h2 className="text-center">Typography</h2>
          <hr />
          <div className="padding-block-end-4">
            <h1 className="display-light margin-block-end-4">Heading 1</h1>
            <h1 className="display-bold margin-block-end-4">Heading 1</h1>
            <h2 className="display-light margin-block-end-4">Heading 2</h2>
            <h2 className="display-bold margin-block-end-4">Heading 2</h2>
            <h3 className="display-light margin-block-end-4">Heading 3</h3>
            <h3 className="display-bold margin-block-end-4">Heading 3</h3>
            <p className="display margin-block-end-6">Display Body</p>

            <h1 className="margin-block-end-4">Heading 1</h1>
            <h2 className="margin-block-end-4">Heading 2</h2>
            <h3 className="margin-block-end-4">Heading 3</h3>
            <h4 className="margin-block-end-4">Heading 4</h4>
            <h5 className="margin-block-end-4">Heading 5</h5>
            <p className="margin-block-end-4">Some Body Copy</p>
            <blockquote className="margin-block-end-4">
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Magnam
              voluptas provident ad error dignissimos illo ut. Illum, quasi. Sit
              cupiditate quam molestias autem quasi, delectus molestiae. Earum,
              eligendi esse, voluptate voluptatem enim vitae facilis minima vero
              nihil, doloremque omnis atque nulla voluptatibus rerum harum non
              natus deleniti perferendis?
            </blockquote>
          </div>
        </section>

        <section id="formcontrol">
          <h2 className="text-center">Form Controls</h2>
          <hr />
          <div className="padding-block-6">
            <Checkbox name="toppings" placeholder="Mushrooms" />
            <Checkbox name="toppings" placeholder="Chillis" />
            <Checkbox name="toppings" theme="yellow" placeholder="Cheese" />
            <Checkbox name="toppings" theme="yellow" placeholder="Sausage" />
          </div>

          <div className="padding-block-6">
            <Checkbox
              name="toppings"
              checkType="tick"
              placeholder="Mushrooms"
            />
            <Checkbox name="toppings" checkType="tick" placeholder="Chillis" />
            <Checkbox
              name="toppings"
              theme="yellow"
              checkType="tick"
              placeholder="Cheese"
            />
            <Checkbox
              name="toppings"
              theme="yellow"
              checkType="tick"
              placeholder="Sausage"
            />
          </div>

          <div className="padding-block-6">
            <RadioButton name="age" placeholder="0 - 16" />
            <RadioButton name="age" placeholder="17 - 21" />
            <RadioButton name="age" theme="yellow" placeholder="22 - 31" />
            <RadioButton name="age" theme="yellow" placeholder="31+" />
          </div>
        </section>

        <section id="anchor">
          <h2 className="text-center">Anchor Tags</h2>
          <hr />
          <div className="margin-block-end-5">
            <a href="#anchor">I'm a boring link</a>
          </div>

          <div className="margin-block-end-5">
            <a className="with-arrow" href="#anchor">
              I'm a blue link
            </a>
            <br />
            <a className="with-arrow no-transform" href="#anchor">
              I'm a blue link (no text transform)
            </a>
          </div>

          <div className="margin-block-end-5">
            <a className="with-arrow orange" href="#anchor">
              I'm an orange link
            </a>
            <br />
            <a className="with-arrow orange no-transform" href="#anchor">
              I'm an orange link (no text transform)
            </a>
          </div>

          <div className="margin-block-end-5">
            <a className="with-arrow yellow" href="#anchor">
              I'm a yellow link
            </a>
            <br />
            <a className="with-arrow yellow no-transform" href="#anchor">
              I'm a yellow link (no text transform)
            </a>
          </div>

          <div className="margin-block-end-5">
            <a className="with-arrow royal" href="#anchor">
              I'm a royal link
            </a>
            <br />
            <a className="with-arrow royal no-transform" href="#anchor">
              I'm a royal link (no text transform)
            </a>
          </div>

          <div className="margin-block-end-5">
            <a className="with-underline" href="#anchor">
              I'm an underline link
            </a>
            <br />
            <a className="with-underline no-transform" href="#anchor">
              I'm an underline link (no text transform)
            </a>
          </div>
        </section>

        <section id="articleheader">
          <h2 className="text-center">Article Header</h2>
          <hr />
          <ArticleHeader imageUrl={artle} articleTitle="My Article" />
        </section>

        <section id="scrollarrow">
          <h2 className="text-center">Scroll Down Arrow</h2>
          <hr />
          <div className="margin-block-end-5">
            <ScrollDownArrow label="Scroll Down Now" theme="yellow" />
          </div>

          <div className="margin-block-end-5">
            <ScrollDownArrow label="Feeling blue? Scroll Down" theme="blue" />
          </div>

          <div>
            <ScrollDownArrow
              label="That's rigth, you can scroll down"
              theme="orange"
            />
          </div>
        </section>

        <section id="confetti">
          <h2 className="text-center">Confetti</h2>
          <hr />
          <div
            className="margin-block-end-5 text-center"
            style={{ position: 'relative' }}
            onMouseEnter={addConfetti}
          >
            <p className="text-center">Hover Me</p>
          </div>
          <div
            className="margin-block-end-5 text-center"
            style={{ position: 'relative' }}
            onClick={addConfetti}
          >
            <p className="text-center">Click Me</p>
          </div>
          <div
            className="margin-block-end-5 text-center"
            style={{ position: 'relative' }}
            onDoubleClick={addConfetti}
          >
            <p className="text-center">Double Click Me</p>
          </div>
        </section>

        <section id="largecard">
          <h2 className="text-center">Large Article Card</h2>
          <hr />
          <LargeArticleCard
            image={[header, header, header]}
            title="Hello World"
            description="Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nihil sapiente quaerat fuga quia omnis nostrum quisquam quod cupiditate dignissimos quae, repellendus quasi rerum. Nobis vitae voluptatum dignissimos, dolorem accusantium illo."
            url="#largecard"
            buttonText="Read More"
          />
        </section>

        <section className={styles.mapWrapper} id="map">
          <h2 className="text-center">Google Maps Image</h2>
          <hr />
          <GoogleMap></GoogleMap>
        </section>

        <section id="input">
          <h2 className="text-center">Input Field</h2>
          <hr />
          <form
            onSubmit={handleSubmit(console.log)}
            noValidate
            autoComplete="off"
          >
            <div className="padding-block-end-6">
              <InputField
                placeholder="Your name"
                name="name"
                leadHint="+"
                errors={errors}
                inputRef={register({
                  required: 'Please tell us your first name',
                  maxLength: {
                    value: 15,
                    message: 'This field cannot be more than 15 characters',
                  },
                })}
              />
            </div>
            <div className="padding-block-end-6 padding-block-start-2">
              <InputField
                placeholder="Your age"
                name="age"
                type="number"
                errors={errors}
                inputRef={register({
                  required: 'Please tell us your age',
                  max: {
                    value: 99,
                    message: 'Sorry, you cannot be older that 99',
                  },
                  min: {
                    value: 10,
                    message: 'Sorry, you cannot be younger than 10',
                  },
                })}
              />
            </div>
            <div className="padding-block-end-6 padding-block-start-2">
              <Dropdown
                inputRef={register({ required: 'Please choose a toppping' })}
                errors={errors}
                name="toppingsforpizza"
                placeholder="Choose an industry"
              >
                <option value="1">Making stuff</option>
                <option value="2">Breaking stuff</option>
                <option value="3">Managing stuff</option>
                <option value="4">Finding stuff</option>
              </Dropdown>
            </div>
            <div className="padding-block-end-6 padding-block-start-2">
              <InputField
                placeholder="Your email"
                name="email"
                type="email"
                errors={errors}
                inputRef={register({
                  required: 'Please give us your email address',
                  validate: {
                    email: validators.email,
                  },
                })}
              />
            </div>
            <div className="padding-block-start-2 text-right">
              <Button theme="dark">Go</Button>
            </div>
          </form>
        </section>

        <section></section>
      </article>
    </div>
  );
}
