import React from 'react';
import { NavLink } from 'react-router-dom';
import styles from './Footer.module.scss';
import logo from '../../assets/phiresky-white.png';
import pkg from '../../../package.json';
import { addConfetti } from '../lib/confetti/confetti';

export function Footer(): JSX.Element {
  return (
    <footer className={`${styles.footer}`}>
      <div className={`${styles.footerLinks} padding-block-6`}>
        <div className="container">
          <div
            className={`${styles.footerLogo} margin-inline-auto margin-block-end-3`}
          >
            <img
              title={`${process.env.BRANCH} | ${pkg.version}`}
              src={logo}
              alt="Phiresky Logo"
            />
          </div>

         

          <div className={`${styles.footerSitemap}`}>
            <ul>
              <li className="padding-inline-2">
                <NavLink to="/">Home</NavLink>
              </li>

           
              <li className="padding-inline-2">
                <NavLink to="/solutions">Solutions</NavLink>
              </li>

              <li className="padding-inline-2">
                <NavLink to="/about">About</NavLink>
              </li>

              <li className="padding-inline-2">
                <NavLink to="/contact">Contact</NavLink>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className={`${styles.footerLegal} padding-block-4`}>
        <div className={`container ${styles.footerLegalInner}`}>
          <div className={`${styles.footerCopyright}`}>
            &copy;{new Date().getFullYear()} Phiresky (Pty) LTD.
            All rights reserved.
          </div>

          <div className={`${styles.footerCopyrightLinks}`}>
            <ul>
              <li onMouseEnter={addConfetti}>
                <NavLink to="/legal/terms-and-conditions">Terms of Use</NavLink>
              </li>

              <li onMouseEnter={addConfetti}>
                <NavLink to="/legal/privacy-policy">Privacy policy</NavLink>
              </li>

              <li onMouseEnter={addConfetti}>
                <NavLink to="/legal/cookie-policy">Cookie policy</NavLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}
