import { getRandomNumber } from '../utils/getRandomNumber';

// The colors that the confetti pieces
// will be. When each piece of confetti
// is created, it is given a random color
const colors: string[] = [
  'var(--sky)',
  'var(--breeze)',
  'var(--energy)',
  'var(--fabulous)',
  'var(--atlantis)',
  'var(--persian)',
  'hotpink',
  'orange',
  'tomato',
  'aqua',
];

// A list of cubic bezier functions
// that will give each confetti piece
// a different behaviour
const easings: string[] = [
  'cubic-bezier(0,0,0,.99)',
  'cubic-bezier(0,0,.29,.98)',
  'cubic-bezier(0,0,.21,.97)',
  'cubic-bezier(0,0,0,1.08)',
  'cubic-bezier(0,-0.01,.54,1.13)',
  'cubic-bezier(0,-0.01,0,.62)',
];

// The speed at which the the pieces
// will animate. The speed will be
// a millisecond value between the
// given values
const timingRange: [number, number] = [210, 510];

// The amount of time that the will
// have to elapse before the confetti
// can be generated again
const cooldownPeriod: number = 300;

// Generates an empty array of null
// values at the given length. This
// will be used to generate the
// pieces of confetti
function getUnfilledArray(length: number = 20): Array<null> {
  return Array.from(Array(length), () => null);
}

// Gets the event target from the
// given source event.
function extractTarget(event: any): HTMLElement {
  return event.currentTarget as any;
}

// Given a array, this function
// will return a random value from
// that array.
function getRandomArrayValue<T>(array: T[]): T {
  return array[Math.floor(Math.random() * array.length)];
}

// Checks if the current device is
// touch or mouse based
const isTouch = window.matchMedia('screen and (pointer: coarse)');

export function addConfetti(event: any): void {
  console.log(event.type);
  const target = extractTarget(event);

  if (
    target.classList.contains('cannot-hover') ||
    (isTouch.matches && event.type.includes('mouse'))
  ) {
    return;
  } else target.classList.add('cannot-hover');

  const items = getUnfilledArray();

  for (let i = 0; i < items.length; i++) {
    const span = document.createElement('span');
    const color = getRandomArrayValue(colors);
    const easing = getRandomArrayValue(easings);
    const speed = getRandomNumber(timingRange);
    const rotation = getRandomNumber([0, 360], false);
    const growTo = getRandomNumber([1, 2], false);

    span.className = 'confetti-item';
    span.setAttribute(
      'style',
      `background:${color};transition: transform ${easing} ${speed}ms, opacity ${easing} ${speed}ms 50ms;transform:rotate(${rotation}deg) translateY(0) scale(0);`
    );

    target.appendChild(span);

    setTimeout(() => {
      setTimeout(() => {
        span.style.opacity = '1';
        span.style.transform = `rotate(${rotation}deg) translateY(0) scale(0)`;

        setTimeout(() => {
          span.style.transform = `rotate(${rotation}deg) translateY(-60px) scale(${growTo})`;
          span.style.opacity = '0';

          setTimeout(() => {
            span.remove();
            target.classList.remove('cannot-hover');
          }, speed + cooldownPeriod);
        }, speed / 2);
      });
    }, 0);
  }
}
