import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import * as sw from './serviceWorker';

const root = document.getElementById('root');

if (root?.hasChildNodes()) {
  ReactDOM.hydrate(<App />, root);
} else {
  ReactDOM.render(<App />, root);
}

// Registers a service worker to help with page speed
if (!(!process.env.NODE_ENV || process.env.NODE_ENV === 'development')) {
  sw.register({});
} else sw.unregister();
