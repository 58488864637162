import React, { useState } from 'react';
import styles from './LoadMore.module.scss';
import loader from '../../assets/loader.svg';

export function LoadMore(props: { onClick?: () => void }): JSX.Element | null {
  const [loading, setIsLoading] = useState(false);

  return (
    <button
      className={`${styles.loadMoreButton} ${loading ? styles.isLoading : ''}`}
      onClick={() => {
        setIsLoading(true);
        if (typeof props.onClick === 'function') props.onClick();
      }}
    >
      Load More
      <span>
        <object data={loader} type="image/svg+xml">
          <img src={loader} alt="Loader" />
        </object>
      </span>
    </button>
  );
}
