const faces = [
  '🥳',
  '😎',
  '🦄',
  '💪',
  '🚀',
  '🙂',
  '👌',
  '🤙',
  '🤩',
  '👏',
  '⚡️',
  '👊',
  '🤟',
];

export function randomEmoji(): string {
  const key = Math.floor(Math.random() * faces.length);
  return faces[key];
}
