import React, { useEffect, useState } from 'react';
import styles from './Home.module.scss';
import {
  PageHeader,
  TextSlider,
  Button,
  LargeArticleCard,
  MasonryGrid,
} from '../../components';
import header from '../../assets/home_animation.svg';

import { Helmet } from 'react-helmet';
import useFetch from 'react-fetch-hook';
import { configs } from '../../env';
import createTrigger from 'react-use-trigger';
import useTrigger from 'react-use-trigger/useTrigger';
import { Article } from '../../models/article';
import { useFeaturedCaseStudy } from '../../components/lib/hooks/useFeaturedCaseStudy';

const requestTrigger = createTrigger();

const questions = ['customers', 'business', 'users', 'systems', 'software'];

let index = 0;

export function Home(): JSX.Element {
  const trigger = useTrigger(requestTrigger);
  const [articles, setArticles] = useState<any>([]);
  const { featured } = useFeaturedCaseStudy();
  

  const { data } = useFetch<any>(
    `${configs.baseApiUrl}/Articles?pageSize=6&pageNumber=${index}&sortField=publishDate&sortDescending=true`,
    {
      depends: [trigger],
    }
  );

  useEffect(() => {
    if (!!data?.length) {
      setArticles([
        ...articles,
        data.map((art: Article) => {
          if (art.snippet.length > 100) {
            art.snippet = art.snippet.substring(0, 100).trim() + '...';
          }

          return art;
        }),
      ]);
    } else if (Array.isArray(data) && data.length === 0) {
     
    }
    // eslint-disable-next-line
  }, [data]);

  return (
    <div className={`page ${styles.homePage} margin-inline-auto`}>
      <Helmet>
        <title>Phiresky | CX-led software consulting</title>
        <meta
          name="description"
          content="We don’t just churn out nifty apps and pretty web pages. We create software that’s part of a larger solution – a solution that bridges the customer-business divide and delivers ROI. How? It all starts with asking better questions."
        />
      </Helmet>

      {/* <PageHeader actionText="See Our Work" actionUrl="/work" imageUrl={header}> */}
      <PageHeader actionText="See Our Solutions" actionUrl="/solutions" imageUrl={header}>
        <div className={styles.headerInnerWrapper}>
          <TextSlider questions={questions} />
          <div className={styles.buttonWrapper}>
            <Button as="a" href="/contact">
              Get Better Answers
            </Button>
          </div>
        </div>
      </PageHeader>

      <br />

      {!!featured && (
        <article
          style={{ border: 'none' }}
          className="padding-block-start-6 padding-block-end-3 margin-block-end-6"
        >
          <LargeArticleCard
            image={featured.featuredImage}
            title={featured.title}
            description={featured.snippet}
            url={`/work/case-study/${featured.slug}`}
            buttonText={featured.buttonText ?? 'Read More'}
          />
        </article>
      )}

      <article className="padding-block-6">
        <h2 className="h1 margin-block-end-1">Latest insights</h2>

        <p className={`margin-block-end-5 greyed`}>
          Get a taste of what our team is thinking and talking about.
        </p>

        {data && (
          <MasonryGrid
            onNeedsMore={() => {
              index++;
              requestTrigger();
            }}
           
            rows={articles as any}
          />
        )}
      </article>
    </div>
  );
}
