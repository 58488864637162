import React from 'react';
import styles from './RadioButton.module.scss';

interface RadioProps {
  name: string;
  placeholder: string;
  theme?: 'blue' | 'yellow';
  value?: string;
  checked?: boolean;
  onChecked?: (value?: string) => void;
}

export function RadioButton(props: RadioProps): JSX.Element {
  const { name, placeholder, theme = 'blue', value } = props;

  return (
    <div className={`${styles.inputWrapper} ${styles[theme]}`}>
      <input
        className={styles.radioInput}
        tabIndex={0}
        type="radio"
        name={name}
        checked={props.checked}
        id={name}
        value={value}
        onChange={() => {
          if (typeof props.onChecked !== 'undefined') {
            props.onChecked(value);
          }
        }}
      />

      <div className={styles.radioWrapper} tabIndex={-1}>
        <div className={styles.radio} tabIndex={-1}>
          <span tabIndex={-1}></span>
        </div>
        <div className={styles.radioLabel} tabIndex={-1}>
          {placeholder}
        </div>
      </div>
    </div>
  );
}
