import React, { FormEvent, KeyboardEvent, useState } from 'react';
import styles from './Textarea.module.scss';
import { NestDataObject, ErrorMessage } from 'react-hook-form';
import { InputRef } from '../../models/InputRef';

interface TextareaProps {
  name: string;
  maxLength?: number;
  limitText?: string;
  errors?: NestDataObject<Record<string, any>>;
  inputRef?: InputRef<HTMLTextAreaElement>;
}

export function Textarea(props: TextareaProps): JSX.Element {
  const [count, setCount] = useState(0);

  function handleInputSize({ target }: FormEvent<HTMLTextAreaElement>): void {
    if (target instanceof HTMLTextAreaElement) {
      const input: HTMLTextAreaElement = target;
      input.style.height = `${input.scrollHeight}px`;
      countLength(target);
    }
  }

  function handleInputLength(event: KeyboardEvent<HTMLTextAreaElement>): void {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      return;
    }

    if (!!props.maxLength) {
      const target = event.target as HTMLTextAreaElement;
      if (
        target.value.length >= props.maxLength &&
        !(
          event.key === 'Backspace' ||
          event.key === 'Tab' ||
          event.key === 'ArrowRight' ||
          event.key === 'ArrowLeft'
        )
      ) {
        event.preventDefault();
        event.stopPropagation();
      }
    }
  }

  function countLength(input: HTMLTextAreaElement): void {
    setCount(input.value.length);
  }

  const { name, errors } = props;
  const errorclass = !!errors && !!errors[name] ? styles.fieldHasErrors : '';

  return (
    <div className={`${styles.textareaWrapper} ${errorclass}`}>
      <textarea
        ref={props.inputRef}
        name={name}
        id={name}
        onInput={handleInputSize}
        onKeyDown={handleInputLength}
        placeholder="Enter you answer here"
      ></textarea>
      <div className={`${styles.inputUnderline}`}></div>
      {props.maxLength && (
        <div className={styles.inputCount}>
          {count < props.maxLength && (
            <>
              {count}/{props.maxLength}
            </>
          )}
          {count === props.maxLength && (
            <>{props.limitText || `${count}/${props.maxLength}`}</>
          )}
        </div>
      )}
      {!!props.errors && (
        <ErrorMessage
          className={styles.inputErrorMessage}
          errors={props.errors}
          name={props.name}
          as="div"
        />
      )}
    </div>
  );
}
