import React, { useState } from 'react';
import { Button } from '../button/Button';
import styles from './CookieNotice.module.scss';
import cookie from './CookieNotice.png';

export function CookieNotice() {
  const value = window.localStorage.getItem('hasCookied');

  const [hide, setHide] = useState(value === 'true');

  function setValue() {
    window.localStorage.setItem('hasCookied', 'true');
  }

  function accept() {
    setValue();
    setHide(true);
  }

  if (!hide) {
    return (
      <div className={styles.CookieNotice}>
        <div className={styles.CookieNoticeImage}>
          <img draggable={false} src={cookie} alt="Cookie" />
        </div>
        <div className={styles.CookieNoticeContent}>
          <h6>This website uses cookies</h6>
          <p>
            We care about data, and use cookies to make your experience better
          </p>

          <div className={styles.CookieNoticeActions}>
            <Button
              as="a"
              href="/legal/cookies-manage"
              isSmall={true}
              hasArrow={false}
              theme="light"
              onClick={() => accept()}
            >
              Manage
            </Button>
            <Button isSmall={true} hasArrow={true} onClick={() => accept()}>
              I Accept
            </Button>
          </div>
        </div>
      </div>
    );
  } else return null;
}
