type Evt = React.KeyboardEvent<HTMLInputElement>;

type ModifierKey = 'altKey' | 'shiftKey' | 'ctrlKey' | 'metaKey';

export function hasModifierKey(e: Evt, ...m: ModifierKey[]): boolean {
  if (m.length) {
    return m.some(modifier => e[modifier]);
  }

  return e.altKey || e.shiftKey || e.ctrlKey || e.metaKey;
}

export function hasKeycode(event: Evt, ...codes: number[]): boolean {
  return codes.some(value => (event.keyCode || event.which) === value);
}
