import React, { PropsWithChildren } from 'react';
import styles from './PageHeader.module.scss';
import { ShowWhenReact } from '../../lib/utils/showOnReact';

interface PageHeaderProps {
  actionText?: string;
  actionUrl?: string;
  imageUrl: string;
}

export function PageHeader(
  props: PropsWithChildren<PageHeaderProps>
): JSX.Element {
  return (
    <header className={`${styles.pageHeader}`}>
      <div className={`${styles.headerInnerWrapper}`}>
        <div className={`${styles.headerContent}`}>{props.children}</div>
        <div className={`${styles.headerImage}`}>
          <ShowWhenReact elseCase={<img src={props.imageUrl} alt="Home" />}>
            <object data={props.imageUrl} type="image/svg+xml">
              <img loading="lazy" src={props.imageUrl} alt="Home Header" />
            </object>
          </ShowWhenReact>
        </div>
      </div>
      <div className={`${styles.headerActionLink}`}>
        {!!props.actionText && (
          <a href={props.actionUrl} className="with-arrow blue">
            {props.actionText}
          </a>
        )}
      </div>
    </header>
  );
}
