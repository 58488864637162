import React from 'react';
import { HeadlessCard, CardProps, createImageList } from '../HeadlessCard';
import styles from './LargeArticleCard.module.scss';
import { Button } from '../../button/Button';
import dots from '../../../assets/dots.png';

export function LargeArticleCard(props: CardProps): JSX.Element {
  return (
    <HeadlessCard {...props}>
      {({ title, url, image, description, buttonText = 'Read More' }) => {
        return (
          <a href={url} className={`${styles.largeArticleCardWrapper}`}>
            <div className={styles.dots}>
              <img loading="lazy" src={dots} alt="Dots" />
            </div>
            <div className={`${styles.cardImage}`}>
              {!Array.isArray(image) ? (
                <img loading="lazy" src={image} alt={title} />
              ) : (
                createImageList(image, title)
              )}
            </div>
            <div
              className={`${styles.cardContent} padding-block-5 padding-inline-4`}
            >
              <h3 className={`margin-block-end-2`}>{title}</h3>
              <p className="text-left margin-block-end-3">{description}</p>
              <Button>{buttonText}</Button>
            </div>
          </a>
        );
      }}
    </HeadlessCard>
  );
}
