// eslint-disable-next-line
const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const Validators = {
  yourName: {
    required: `We can’t develop your solution if you don’t tell us who you are 🤨`,
    validate: {
      noNumbers: (value: string) => {
        const rgx = /[0-9]+/gi;
        return (
          !rgx.test(value) || `Sorry, but you can’t enter any numbers here 😕`
        );
      },
    },
  },
  solutionDetails: {
    required: `Type out what you need here 👀`,
    maxLength: {
      value: 300,
      message: `Say no more. We can discuss this together in detail later 👍`,
    },
  },
  dialingCode: {
    required: `Enter a code 🌍`,
    pattern: {
      value: /[0-9]+/,
      message: 'Only numbers please ✋',
    },
  },
  phone: {
    required: `Enter your phone number so we can contact you 📞`,
    validate: {
      maxNumberLength: (value: number | string) => {
        const val = value.toString().trim();
        return val.length <= 15 || `That’s too long to be a phone number 🧐`;
      },
    },
  },
  email: {
    required: `Enter your email address so we can contact you 💌`,
    validate: {
      emailIsValid: (value: string) => {
        return (
          emailRegex.test(String(value)) ||
          `Looks like you might have made a typo here 🧐`
        );
      },
    },
  },
};

export { Validators };
