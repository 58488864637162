import React, { useLayoutEffect } from 'react';
import styles from './Pong.module.scss';
import { startGameLogic } from './game-logic';

export function Pong(): JSX.Element {
  useLayoutEffect(() => {
    const canvas = document.querySelector('canvas#pong') as HTMLCanvasElement;
    return startGameLogic(canvas);
  });
  return <canvas id="pong" className={styles.pongCanvas}></canvas>;
}
