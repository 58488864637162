import React from 'react';
import { CardProps, HeadlessCard } from '../HeadlessCard';
import styles from './MediumArticleCard.module.scss';
import { Button } from '../../button/Button';
import { Chip } from '../../chip/Chip';

interface MediumCardProps extends CardProps {
  tags: string[];
  year: number;
  isFeatured?: boolean;
}

export function MediumArticleCard(props: MediumCardProps): JSX.Element {
  return (
    <HeadlessCard {...props}>
      {({ title, url, image, description, buttonText = 'Read More' }) => {
        if (Array.isArray(image)) image = image[0];

        return (
          <a href={url} className={`${styles.mediumArticleCard}`}>
            <div
              className={`${styles.mediumCardImage}${
                props.isFeatured ? ' ' + styles.featuredImage : ''
              }`}
            >
              <img loading="lazy" draggable="false" src={image} alt={title} />
            </div>
            <div className={styles.mediumCardContent}>
              <div className={`${styles.cardDate} margin-block-end-4`}>
                {props.year}
              </div>

              <h2 className="margin-block-end-3">{title}</h2>

              <div className={styles.cardDescription}>
                <p className="text-left margin-block-end-2">{description}</p>
              </div>

              <Button as="span" theme="light">
                {buttonText}
              </Button>

              {props.tags?.length && (
                <ul className={`${styles.chipsList}`}>
                  {props.tags.map((tag, i) => {
                    return (
                      <li key={i}>
                        <Chip title={tag} />
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
          </a>
        );
      }}
    </HeadlessCard>
  );
}
