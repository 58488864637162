import React, { useLayoutEffect } from 'react';
import styles from './TextSlider.module.scss';

interface TextSliderProps {
  questions: string[];
}

function CheckIsVisible(el: Element) {
  const height = window.innerHeight;
  const width = window.innerWidth;
  const { top, left, bottom, right } = el.getBoundingClientRect();
  return top >= 0 && left >= 0 && bottom <= height && right <= width;
}

export function TextSlider(props: TextSliderProps): JSX.Element {
  useLayoutEffect(() => {
    const line = document.querySelector(
      `.${styles.line}.${styles.lineTwo} .${styles.textSliderRotation}`
    );

    const items = Array.from(line?.children ?? []);

    items[0].classList.add('active');

    let index = 0;

    const it = setInterval(() => {
      if (document.hasFocus() && CheckIsVisible(line)) {
        items[index].classList.remove('active');

        if (index === items.length - 1) {
          index = 0;
        } else index++;

        items[index].classList.add('active');
      } else {
        items.forEach(i => i.classList.remove('active'));
        items[0].classList.add('active');
        index = 0;
      }
    }, 2600);

    return () => {
      clearInterval(it);
    };
  });

  return (
    <div className={`${styles.textSlider}`}>
      <h1 className={`${styles.line} ${styles.lineOne} h2 margin-block-end-2`}>
        <strong>Asking</strong> better <br className={styles.viewMobile} />{' '}
        questions <span className={styles.viewMobile}>about your</span>
      </h1>
      <h1 className={`${styles.line} ${styles.lineTwo} h2`}>
        <span className={styles.viewNotMobile}>about your </span>
        <div className={`${styles.textSliderRotation}`}>
          {props.questions.map((item, key) => {
            return (
              <span key={key}>
                {item}
                <i className={'dot'}></i>
              </span>
            );
          })}
        </div>
      </h1>
    </div>
  );
}
