import { getRandomNumber } from '../lib/utils/getRandomNumber';

// These two functions will return
// the width and height of the window.
// These are wrapped in functions so
// that we never have stale values.
const canvasWidth = () => window.innerWidth;
const canvasHeight = () => window.innerHeight;

// This is the initial speed of the
// ball. Everytime a round is completed,
// this value is incremented slightly
let initialBallSpeed = 7;

// This is experimental, but we load
// in sounds that can be played when
// certain events happen in the game
const wall = new Audio('/sounds/wall.mp3');
const botWin = new Audio('/sounds/comScore.mp3');
const youWin = new Audio('/sounds/userScore.mp3');
const hit = new Audio('/sounds/hit.mp3');

class Player {
  public width: number = 50;
  public height: number = 200;

  public score: number = 0;

  constructor(
    public x: number = 50,
    public y: number = 0,
    public speed: number = 4
  ) {}

  public manualMove(to: number) {
    const maxHeight = canvasHeight() - this.height;

    this.y += to;

    if (this.y >= maxHeight) {
      this.y = maxHeight;
    } else if (this.y <= 0) {
      this.y = 0;
    }
  }

  public autonomousMove(ball: Ball): void {
    if (ball.x > canvasWidth() / 3) {
      const ballY = ball.y;

      if (ballY > this.y + this.height) {
        this.y += this.speed;
      } else if (ballY < this.y) {
        this.y -= this.speed;
      }
    }
  }
}

class Ball {
  public radius: number = 20;
  public speed: number = initialBallSpeed;
  public velocityX: number = -initialBallSpeed;
  public velocityY: number = getRandomNumber([-1, 1]);
  public color: string = '#ffc700';

  private get top(): number {
    return this.y - this.radius;
  }

  private get bottom(): number {
    return this.y + this.radius;
  }

  private get left(): number {
    return this.x - this.radius;
  }

  private get right(): number {
    return this.x + this.radius;
  }

  constructor(public x: number = 0, public y: number = 0) {}

  public update(
    user: Player,
    bot: Player,
    reset: (p: number, b: number) => void
  ): void {
    this.x += this.velocityX;
    this.y += this.velocityY;

    if (this.bottom > canvasHeight() || this.top < 0) {
      this.flipY();
    }

    const userCollide = this.checkPaddleCollision(user);
    const botCollide = this.checkPaddleCollision(bot);

    if (botCollide || userCollide) {
      const player = userCollide === true ? user : bot;
      this.flipX(player);
    } else if (this.x < 0) {
      reset(user.score, bot.score + 1);
      botWin.play();
    } else if (this.x > canvasWidth()) {
      reset(user.score + 1, bot.score);
      youWin.play();
    }
  }

  private flipY(): void {
    this.velocityY = -this.velocityY;
    wall.play();
  }

  private flipX(player: Player): void {
    const height = player.height / 2;
    const collided = ((this.y - (player.y + height)) / height) * -1;
    const angle = (Math.PI / 4) * collided;

    const direction = this.x < canvasWidth() / 2 ? 1 : -1;

    this.velocityX = direction * this.speed * Math.cos(angle);
    this.velocityY = this.speed * Math.sin(angle);

    this.speed += 0.18;

    hit.play();
  }

  private checkPaddleCollision(paddle: Player): boolean {
    const top = paddle.y;
    const bottom = paddle.y + paddle.height;
    const right = paddle.x + paddle.width;
    const left = paddle.x;

    return (
      left < this.right &&
      top < this.bottom &&
      right > this.left &&
      bottom > this.top
    );
  }
}

export function startGameLogic(canvas: HTMLCanvasElement): any {
  // We need to set up the size of the canvas
  // to be the width and height of the screen.
  // We also need to listen to the screen
  // resize event and update the size of the
  // canvas accordingly
  const sizeCanvas = () => {
    canvas.width = canvasWidth();
    canvas.height = canvasHeight();
  };

  sizeCanvas();

  window.addEventListener('resize', sizeCanvas);

  // Here we grab the context of the canvas
  // if we are unable to get the context, it
  // means that something went very wrong
  // and we cannot start the game, so we
  // return
  const context = canvas.getContext('2d');
  if (!context) return;

  // Get the placement for both player's
  // paddles on the Y axis. This placement
  // is centered vertically on the screen.
  const paddleYStart = canvas.height / 2 - 100;

  // Here we create the user and the bot
  // players for the game. They are given
  // the same placement on the Y axis, but
  // the user is placed on the left of the
  // screen while the bot is on the right
  // of the screen.
  const user = new Player(50, paddleYStart, 6);
  const bot = new Player(canvas.width - 100, paddleYStart);

  // We also need a ball that we can play
  // the game with. The ball is centered
  // vertically and horizontally on the
  // board.
  const ball = new Ball(canvas.width / 2, canvas.height / 2);

  // Here we hold the value of the user's
  // movements. We are listening to the
  // keydown event and if the user pushes
  // the up arrowm the value of this variable
  // will be set to a negative value. If the
  // user pushes the down arrow, the value
  // will be set to a positive value. When
  // the key is not pressed anymore, the
  // value is reset to 0
  let yIncrementer = 0;

  const keydownEvent = ({ key }: KeyboardEvent) => {
    if (key === 'ArrowUp' || key === 'w') {
      yIncrementer = -user.speed;
    } else if (key === 'ArrowDown' || key === 's') {
      yIncrementer = user.speed;
    }
  };
  window.addEventListener('keydown', keydownEvent);

  const keyupEvent = () => (yIncrementer = 0);
  window.addEventListener('keyup', keyupEvent);

  // Once the game is set up, we can start
  // rendering the board. The board is rendered
  // at 60fps (if the browser can handle that
  // speed). The game's rendering cycle is
  // stored in a variable so that when the game
  // is stopped, we can stop running the
  // rendering code.
  const gameFrames = setInterval(() => {
    // The game play revolves mostly around the
    // the ball. We use the ball to check if
    // there has been a collision with the user
    // paddles, if the ball has collided with a
    // wall, or if a player has scored. The
    // callback function in this function is
    // run if one of the players has scored
    ball.update(user, bot, (p: number, b: number) => {
      // Checks which player has scored and
      // boosts the speed of the other player's
      // paddle. The speed of either player's
      // paddle cannot exceed 16
      if (p > user.score) bot.speed = Math.min(16, bot.speed + 0.25);
      if (b > bot.score) user.speed = Math.min(16, user.speed + 1.25);

      // Set the score for each player. The
      // score will only change for one at
      // at time, but we set both to make
      // sure that everything is correct
      bot.score = b;
      user.score = p;

      // We now need to reset the ball to it's
      // initial starting point at the center
      // of the board
      ball.x = canvas.width / 2;
      ball.y = canvas.height / 2;

      // We will also change the direction of
      // the ball so that it heads towards the
      // player that scored
      ball.velocityX = -ball.velocityX;
      ball.velocityY = getRandomNumber([-1, 1]);

      // We inrement the balls starting speed
      // value each time the ball is reset.
      initialBallSpeed += 0.25;

      // Finally, we set the speed of the ball
      // back to its initial speed (that has
      // been incremented).
      ball.speed = initialBallSpeed;
    });

    // Moves the player based on the user's
    // input. The variable we use here is
    // assigned a value if one of the arrow
    // keys pressed. If not, its value is 0
    user.manualMove(yIncrementer);

    // Let's the ball move "autonomously".
    // The bot player is programmed to
    // follow the Y position of the ball.
    // The bot player also can only move
    // when the ball is within 2/3s of its
    // side of the court.
    bot.autonomousMove(ball);

    // This is noramally unessecary, but
    // if the size of the screen has changed
    // we need to make sure that the bot's
    // paddle is adjusted to the right spot
    // along the X axis of the screen
    bot.x = canvas.width - 100;

    // Once we have set up the variables of
    // the game, we need to rerender the
    // board.
    renderGame(context, user, bot, ball);
  }, 1000 / 60);

  // We return a function that can be called
  // the end the game. It will stop the game
  // from rendering and it will stop listening
  // for user events. It will also reset some
  // of the game variables.
  return () => {
    clearInterval(gameFrames);
    window.removeEventListener('keydown', keydownEvent);
    window.removeEventListener('keyup', keyupEvent);
    window.removeEventListener('resize', sizeCanvas);
    initialBallSpeed = 7;
  };
}

function drawRectangle(
  context: CanvasRenderingContext2D,
  xCoord: number,
  yCoord: number,
  width: number,
  height: number
): void {
  context.fillStyle = 'white';
  context.fillRect(xCoord, yCoord, width, height);
}

function drawCircle(
  context: CanvasRenderingContext2D,
  xCoord: number,
  yCoord: number,
  radius: number,
  color: string
) {
  context.fillStyle = color;
  context.beginPath();
  context.arc(xCoord, yCoord, radius, 0, Math.PI * 2, false);
  context.closePath();
  context.fill();
}

function drawText(
  context: CanvasRenderingContext2D,
  color: string,
  text: string,
  xCoord: number,
  yCoord: number
) {
  context.fillStyle = color;
  context.font = `300 40px Raleway`;
  context.textAlign = 'center';
  context.fillText(text, xCoord, yCoord);
}

function renderGame(
  ctx: CanvasRenderingContext2D,
  user: Player,
  bot: Player,
  ball: Ball
): void {
  // Before we render the game, we need to
  // clear the current canvas else we will
  // end up with an effect like the old
  // Windows XP window glitch.
  ctx.clearRect(0, 0, canvasWidth(), canvasHeight());

  // Based on the user object we passed in
  // to the function, we now render the user
  // paddle.
  drawRectangle(ctx, user.x, user.y, user.width, user.height);

  // Based on the bot object we passed in
  // to the function, we now render the bot
  // paddle.
  drawRectangle(ctx, bot.x, bot.y, bot.width, bot.height);

  // Based on the ball object passed to the
  // function, we draw the ball on the board
  drawCircle(ctx, ball.x, ball.y, ball.radius, ball.color);

  // Based on the bot and user objects, we
  // can display the score of both players
  // on the screen.
  drawText(
    ctx,
    'white',
    `${user.score}  -  ${bot.score}`,
    canvasWidth() / 2,
    50
  );
}
