import React from 'react';
import './App.scss';
import { About } from './pages/about/About';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Components } from './pages/components-list/Components';
import { Contact } from './pages/contact/Contact';
import { Helmet } from 'react-helmet';
import { Home } from './pages/home/Home';
import { Insights } from './pages/insights/Insights';
import { Navbar, Footer, LazyLoader } from './components';
import { NotFound } from './pages/not-found/NotFound';
import { ScrollToTop } from './components/lib/scroll-restoration/scroll-restoration';
import { Solutions } from './pages/solutions/Solutions';
import { Work } from './pages/work/Work';
import {
  SolutionPrototype as Proto,
  SolutionWebApplication as Web,
  SolutionMobileApplications as Mobile,
  SolutionUsabilityTests as UX,
  SolutionCXServiceDesign as CX,
  SolutionValueProps as Value,
} from './pages/solutions/solutions-view/Solution';
import { CaseStudy } from './pages/work/case-study/CaseStudy';
import { LegalPages } from './pages/legal/Legal';
import { CookieNotice } from './components/cookie-notices/CookieNotice';

function Loaders(): JSX.Element {
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        padding: '50px 0',
        height: 'calc(100vh - 391px)',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <LazyLoader forceShow={true} forceType="a" />
      <LazyLoader forceShow={true} forceType="b" />
    </div>
  );
}

const App = () => {
  return (
    <Router forceRefresh={true}>
      {/* Sets the initial page meta content that will show until a page sets its own content */}
      <Helmet>
        <meta
          name="description"
          content="Phiresky, where awesome works 🦄"
        />
      </Helmet>

      {/* <NoJavaScript /> -- to use, import the component from './components/no-javascript' */}

      {/* Scrolls to the top of the page if the page is changed */}
      <ScrollToTop />

      {/* The global navbar */}
      <Navbar />

      {/* Adds some space for the navbar to float over the content */}
      <div style={{ height: '100px' }}></div>

      {/* The router config */}
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/about" component={About} />
        <Route path="/contact" component={Contact} />
        <Route path="/insights" component={Insights} />
        <Route path="/components" component={Components} />
        <Route path="/work" exact component={Work} />
        <Route path="/work/case-study/:slug" component={CaseStudy} />
        <Route path="/solutions/prototypes" component={Proto} />
        <Route path="/solutions/web-applications" component={Web} />
        <Route path="/solutions/mobile-applications" component={Mobile} />
        <Route path="/solutions/ux-design" component={UX} />
        <Route path="/solutions/cx-design" component={CX} />
        <Route path="/solutions/value-propositions" component={Value} />
        <Route path="/solutions" exact component={Solutions} />
        <Route path="/loaders" component={Loaders} />
        <Route path="/legal/:page" component={LegalPages} />
        <Route path="**" component={NotFound} />
      </Switch>

      {/* The global footer */}
      <Footer />

      <CookieNotice />
    </Router>
  );
};

export default App;
