import React from 'react';
import styles from './Legal.module.scss';
import { TextHeader, LegalNav } from '../../components';

export function CookiePolicy(): JSX.Element {
  return (
    <div className={`${styles.policyPage} page`}>
      <TextHeader lineOne="Cookie" lineTwo="policy"></TextHeader>

      <article>
        <section>
          <LegalNav updated={new Date(2022, 7, 12)}></LegalNav>
        </section>

        <section>
          <h3>Definitions and key terms</h3>
          <p>
            <b>
              To help explain things as clearly as possible in this Cookie
              Policy, every time any of these terms are referenced, are strictly
              defined as:
            </b>
          </p>
          <ul>
            <li>
              <p>
                Cookie: small amount of data generated by a website and saved by
                your web browser. It is used to identify your browser, provide
                analytics, remember information about you such as your language
                preference or login information.
              </p>
            </li>
            <li>
              <p>
                Company: when this policy mentions “Company,” “we,” “us,” or
                “our,” it refers to Phiresky Consulting, that is responsible
                for your information under this Cookie Policy.
              </p>
            </li>
            <li>
              <p>
                Device: any internet connected device such as a phone, tablet,
                computer or any other device that can be used to visit
                Phiresky Consulting and use the services.
              </p>
            </li>
            <li>
              <p>
                Personal Data: any information that directly, indirectly, or in
                connection with other information — including a personal
                identification number — allows for the identification or
                identifiability of a natural person.
              </p>
            </li>
            <li>
              <p>
                Service: refers to the service provided by Phiresky
                Consulting as described in the relative terms (if available) and
                on this platform.
              </p>
            </li>
            <li>
              <p>
                Third-party service: refers to advertisers, contest sponsors,
                promotional and marketing partners, and others who provide our
                content or whose products or services we think may interest you.
              </p>
            </li>
            <li>
              <p>
                Website: Phiresky Consulting's site, which can be
                accessed via this URL: phiresky.com.
              </p>
            </li>
            <li>
              <p>
                You: a person or entity that is registered with Phiresky
                Consulting to use the Services.
              </p>
            </li>
          </ul>
        </section>

        <section>
          <h3>Introduction</h3>
          <p>
            This Cookie Policy explains how Phiresky Consulting and its
            affiliates (collectively "Phiresky Consulting", "we", "us", and
            "ours"), use cookies and similar technologies to recognize you when
            you visit our website, including without limitation phiresky.com
            and any related URLs, mobile or localized versions and related
            domains / sub-domains ("Websites"). It explains what these
            technologies are and why we use them, as well as the choices for how
            to control them.
          </p>
        </section>

        <section>
          <h3>What is a cookie?</h3>
          <p>
            A cookie is a small text file that is stored on your computer or
            other internet connected device in order to identify your browser,
            provide analytics, remember information about you such as your
            language preference or login information. They're completely safe
            and can't be used to run programs or deliver viruses to your device.
          </p>
        </section>

        <section>
          <h3>Why do we use cookies?</h3>
          <p>
            We use first party and/or third party cookies on our website for
            various purposes such as:
          </p>
          <ul>
            <li>
              <p>
                To facilitate the operation and functionality of our website;
              </p>
            </li>
            <li>
              <p>
                To improve your experience of our website and make navigating
                around them quicker and easier;
              </p>
            </li>
            <li>
              <p>
                To allow us to make a bespoke user experience for you and for us
                to understand what is useful or of interest to you;
              </p>
            </li>
            <li>
              <p>
                To analyze how our website is used and how best we can customize
                it;
              </p>
            </li>
            <li>
              <p>
                To identify future prospects and personalize marketing and sales
                interactions with it; and
              </p>
            </li>
            <li>
              <p>
                To facilitate the tailoring of online advertising to your
                interests.
              </p>
            </li>
          </ul>
        </section>

        <section>
          <h3>What type of cookies does Phiresky Consulting use?</h3>

          <p>
            Cookies can either be session cookies or persistent cookies. A
            session cookie expires automatically when you close your browser. A
            persistent cookie will remain until it expires or you delete your
            cookies. Expiration dates are set in the cookies themselves; some
            may expire after a few minutes while others may expire after
            multiple years. Cookies placed by the website you’re visiting are
            called “first party cookies”.
          </p>

          <p>
            Strictly Necessary cookies are necessary for our website to function
            and cannot be switched off in our systems. They are essential in
            order to enable you to navigate around the website and use its
            features. If you remove or disable these cookies, we cannot
            guarantee that you will be able to use our website.
          </p>

          <p>We use the following types of cookies in our website:</p>

          <p>Errors and Omissions Disclaimer.</p>

          <p>
            Phiresky Consulting is not responsible for any content, code or
            any other imprecision.
          </p>

          <p>
            Phiresky Consulting does not provide warranties or guarantees.
          </p>

          <p>
            In no event shall Phiresky Consulting be liable for any special,
            direct, indirect, consequential, or incidental damages or any
            damages whatsoever, whether in an action of contract, negligence or
            other tort, arising out of or in connection with the use of the
            Service or the contents of the Service. Phiresky Consulting
            reserves the right to make additions, deletions, or modifications to
            the contents on the Service at any time without prior notice.
          </p>
        </section>

        <section>
          <h3>General Disclaimer</h3>
          <p>
            The Phiresky Consulting Service and its contents are provided
            "as is" and "as available" without any warranty or representations
            of any kind, whether express or implied. Phiresky Consulting is
            a distributor and not a publisher of the content supplied by third
            parties; as such, Phiresky Consulting exercises no editorial
            control over such content and makes no warranty or representation as
            to the accuracy, reliability or currency of any information,
            content, service or merchandise provided through or accessible via
            the Phiresky Consulting Service. Without limiting the foregoing,
            Phiresky Consulting specifically disclaims all warranties and
            representations in any content transmitted on or in connection with
            the Phiresky Consulting Service or on sites that may appear as
            links on the Phiresky Consulting Service, or in the products
            provided as a part of, or otherwise in connection with, the
            Phiresky Consulting Service, including without limitation any
            warranties of merchantability, fitness for a particular purpose or
            non-infringement of third party rights. No oral advice or written
            information given by Phiresky Consulting or any of its
            affiliates, employees, officers, directors, agents, or the like will
            create a warranty. Price and availability information is subject to
            change without notice. Without limiting the foregoing, Phiresky
            Consulting does not warrant that the Phiresky Consulting Service
            will be uninterrupted, uncorrupted, timely, or error-free.
          </p>
        </section>

        <section>
          <h3>Analytics and Customization Cookies</h3>
          <p>
            These cookies collect information that is used to help us understand
            how our website is being used or how effective our marketing
            campaigns are, or to help us customize our website for you.
          </p>

          <p>
            We use cookies served by Google Analytics to collect limited data
            directly from end-user browsers to enable us to better understand
            your use of our website. Further information on how Google collects
            and uses this data can be found
            at: https://www.google.com/policies/privacy/partners/. You can
            opt-out of all Google supported analytics on our Websites by
            visiting: https://tools.google.com/dlpage/gaoptout.
          </p>
        </section>

        <section>
          <h3>How you can manage cookies?</h3>
          <p>
            Most browsers allow you to control cookies through their 'settings'
            preferences. However, if you limit the ability of websites to set
            cookies, you may worsen your overall user experience, since it will
            no longer be personalized to you. It may also stop you from saving
            customized settings like login information. Browser manufacturers
            provide help pages relating to cookie management in their products.
          </p>

          <p>
            Browser manufacturers provide help pages relating to cookie
            management in their products. Please see below for more information.
          </p>

          <ul>
            <li>
              <p>Google Chrome</p>
            </li>
            <li>
              <p>Internet Explorer</p>
            </li>
            <li>
              <p>Mozilla Firefox</p>
            </li>
            <li>
              <p>Safari (Desktop)</p>
            </li>
            <li>
              <p>Safari (Mobile)</p>
            </li>
            <li>
              <p>Android Browser</p>
            </li>
            <li>
              <p>Opera</p>
            </li>
            <li>
              <p>Opera Mobile</p>
            </li>
          </ul>
        </section>

        <section>
          <h3>Blocking and disabling cookies and similar technologies</h3>
          <p>
            Wherever you're located you may also set your browser to block
            cookies and similar technologies, but this action may block our
            essential cookies and prevent our website from functioning properly,
            and you may not be able to fully utilize all of its features and
            services. You should also be aware that you may also lose some saved
            information (e.g. saved login details, site preferences) if you
            block cookies on your browser. Different browsers make different
            controls available to you. Disabling a cookie or category of cookie
            does not delete the cookie from your browser, you will need to do
            this yourself from within your browser, you should visit your
            browser's help menu for more information.
          </p>
        </section>

        <section>
          <h3>Changes To Our Cookie Policy</h3>
          <p>
            We may change our Service and policies, and we may need to make
            changes to this Cookie Policy so that they accurately reflect our
            Service and policies. Unless otherwise required by law, we will
            notify you (for example, through our Service) before we make changes
            to this Cookie Policy and give you an opportunity to review them
            before they go into effect. Then, if you continue to use the
            Service, you will be bound by the updated Cookie Policy. If you do
            not want to agree to this or any updated Cookie Policy, you can
            delete your account.
          </p>
        </section>
        <section>
          <h3>Your Consent</h3>
          <p>
            By using our website, registering an account, or making a purchase,
            you hereby consent to our Cookie Policy and agree to its terms.
          </p>
        </section>

        <section>
          <h1>Contact Us</h1>

          <p>Don't hesitate to contact us if you have any questions.</p>

          <p>
            Via Email:{' '}
            <a href={'mailto: hello@phiresky.com'}>
              <b>hello@phiresky.com</b>
            </a>
          </p>
        </section>
      </article>
    </div>
  );
}
