import React from 'react';
import styles from './ArticleHeader.module.scss';
import { ShowWhenReact } from '../../lib/utils/showOnReact';

interface ArticleHeaderProps {
  imageUrl: string;
  articleTitle?: string;
}

export function ArticleHeader(props: ArticleHeaderProps): JSX.Element {
  return (
    <header className={styles.articleHeader}>
      <div className={styles.image}>
        <ShowWhenReact elseCase={<img src={props.imageUrl} alt="1" />}>
          <object data={props.imageUrl} type="image/svg+xml">
            <img
              loading="lazy"
              src={props.imageUrl}
              alt={props.articleTitle || 'Article Hero'}
            />
          </object>
        </ShowWhenReact>
      </div>
    </header>
  );
}
