import React from 'react';
import { Button } from '../button/Button';
import styles from './LegalNav.module.scss';

function isActive(route: string) {
  const path = window.location.pathname;
  console.log(route, path);

  return route === path ? styles.activeNavButton : '';
}

function formatDate(date: Date) {
  const dates = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const day = date.getDate().toString();
  const month = dates[date.getMonth()];
  const year = date.getFullYear().toString();

  return `${day.padStart(2, '0')} ${month} ${year}`;
}

interface Props {
  updated?: Date;
}

export function LegalNav({ updated }: Props): JSX.Element {
  return (
    <div className={styles.wrapper}>
      <section className={styles.legalNav}>
        <Button
          className={styles.button + ' ' + isActive('/legal/cookie-policy')}
          hasArrow={false}
          as="a"
          href="/legal/cookie-policy"
          theme="light"
          isPill={true}
        >
          Cookie policy
        </Button>

        <Button
          className={styles.button + ' ' + isActive('/legal/cookies-manage')}
          as="a"
          href="/legal/cookies-manage"
          theme="light"
          isPill={true}
          hasArrow={false}
        >
          Manage cookies
        </Button>

        <Button
          className={styles.button + ' ' + isActive('/legal/privacy-policy')}
          as="a"
          href="/legal/privacy-policy"
          theme="light"
          isPill={true}
          hasArrow={false}
        >
          Privacy policy
        </Button>

        <Button
          className={
            styles.button + ' ' + isActive('/legal/terms-and-conditions')
          }
          as="a"
          href="/legal/terms-and-conditions"
          theme="light"
          isPill={true}
          hasArrow={false}
        >
          Terms of use
        </Button>
      </section>

      {!!updated && (
        <div className={styles.updateItem}>
          <p className={`margin-block-0`}>
            <strong>Last Update:</strong> {formatDate(updated)}
          </p>
        </div>
      )}
    </div>
  );
}
