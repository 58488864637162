import React, { PropsWithChildren } from 'react';
import styles from './Button.module.scss';

interface Arguments {
  isLoading?: boolean;
}

interface Props {
  children: (args: Arguments) => JSX.Element;
  isLoading?: boolean;
}

export function HeadlessButton(props: Props): JSX.Element | null {
  const isLoading = props.isLoading || false;

  if (!props.children || typeof props.children !== 'function') return null;

  return props.children({
    isLoading,
  });
}

interface ButtonProps {
  isLoading?: boolean;
  as?: 'button' | 'a' | 'span';
  href?: string;
  onClick?: (event: MouseEvent) => void;
  className?: string;
  theme?: 'yellow' | 'blue' | 'navy' | 'dark' | 'light';
  isSmall?: boolean;
  isPill?: boolean;
  hasArrow?: boolean;
}

export function Button({
  isLoading = false,
  as = 'button',
  children,
  onClick,
  href,
  className,
  theme = 'yellow',
  isSmall = false,
  isPill = false,
  hasArrow = true,
}: PropsWithChildren<ButtonProps>): JSX.Element | null {
  return (
    <HeadlessButton isLoading={isLoading}>
      {({ isLoading }) => {
        return React.createElement(
          as,
          {
            onClick,
            href,
            className: `${isSmall ? styles.smallButton : ''} 
            ${styles.button} 
            ${className || ''} 
            ${isPill ? styles.pillButton : ''}
            ${isLoading ? ' ' + styles.buttonLoading : ''} ${styles[theme]}${
              hasArrow ? '' : ' ' + styles.noArrow
            }`.trim(),
          },
          <div className={styles.buttonInner}>
            <div className={styles.buttonLabel}>{children}</div>

            {hasArrow ? (
              <div className={styles.buttonArrow}>
                <span></span>
              </div>
            ) : (
              ''
            )}
          </div>
        );
      }}
    </HeadlessButton>
  );
}
