import React, { PropsWithChildren, useState, FormEvent } from 'react';
import styles from './Dropdown.module.scss';
import { NestDataObject, ErrorMessage } from 'react-hook-form';
import { InputRef } from '../../models/InputRef';

interface DropdownPropsStruct {
  name: string;
  placeholder: string;
  showBlank?: boolean;
  multiple?: boolean;
  errors?: NestDataObject<Record<string, any>>;
  inputRef?: InputRef<HTMLSelectElement>;
}

type DropdownProps = PropsWithChildren<DropdownPropsStruct>;

function filterChildren(children: React.ReactNode): React.ReactNodeArray {
  const values = Array.isArray(children) ? children : [children];
  return values.filter((item: any) => item.type === 'option');
}

export function Dropdown(props: DropdownProps): JSX.Element {
  const { name, placeholder, children, showBlank = true, errors } = props;
  const [value, setValue] = useState<string>('');

  function handleValueChanged(event: FormEvent<HTMLSelectElement>): void {
    const select = event.target as HTMLSelectElement;
    const option = select.selectedOptions[0];
    setValue(option.text.trim());
  }

  return (
    <div
      className={`${styles.inputWrapper} ${!!errors &&
        !!errors[name] &&
        styles.fieldHasErrors} ${!!value ? styles.validField : ''}`}
    >
      <select
        onInput={handleValueChanged}
        ref={props.inputRef}
        className={`${styles.selectInput}`}
        name={name}
        id={name}
      >
        {!!showBlank && !value && <option value="">{placeholder} -</option>}
        {filterChildren(children)}
      </select>

      <div className={styles.dropdownWrapper}>
        <label
          htmlFor={name}
          className={`${styles.dropdownLabel} ${!!value && styles.lifted}`}
        >
          {placeholder}
        </label>
        <div className={styles.dropdownValue}>{value}</div>
        <span className={styles.arrow}></span>
        {!!errors && (
          <ErrorMessage
            className={styles.inputErrorMessage}
            errors={errors}
            name={name}
            as="div"
          />
        )}
      </div>
    </div>
  );
}
