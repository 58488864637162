import React, { useEffect, useState } from 'react';
import styles from './Solutions.module.scss';
import { Helmet } from 'react-helmet';
import {
  DetailCard,
  LargeArticleCard,
 
} from '../../components';
import value from '../../assets/solutions/valueproposition.png';
import prototypes from '../../assets/solutions/prototype.png';
import ux from '../../assets/solutions/usability.png';
import web from '../../assets/solutions/webapps.png';
import mobile from '../../assets/solutions/mobileapps.png';
import cx from '../../assets/solutions/cx.png';
import { Article } from '../../models/article';
import { configs } from '../../env';
import useFetch from 'react-fetch-hook';
import useTrigger from 'react-use-trigger/useTrigger';
import createTrigger from 'react-use-trigger';
import { useFeaturedCaseStudy } from '../../components/lib/hooks/useFeaturedCaseStudy';

const requestTrigger = createTrigger();

let index = 0;

export function Solutions(): JSX.Element {
  const trigger = useTrigger(requestTrigger);
  const [articles, setArticles] = useState<any>([]);
  const { featured } = useFeaturedCaseStudy();
 

  const { data } = useFetch<any>(
    `${configs.baseApiUrl}/Articles?pageSize=6&pageNumber=${index}&sortField=publishDate&sortDescending=true`,
    {
      depends: [trigger],
    }
  );

  useEffect(() => {
    if (!!data?.length) {
      setArticles([
        ...articles,
        data.map((art: Article) => {
          if (art.snippet.length > 100) {
            art.snippet = art.snippet.substring(0, 100).trim() + '...';
          }

          return art;
        }),
      ]);
    } else if (Array.isArray(data) && data.length === 0) {
     
    }
    // eslint-disable-next-line
  }, [data]);

  return (
    <div className={`page ${styles.solutionsPage} margin-inline-auto`}>
      <Helmet>
        <title>Phiresky | CX &amp; software solutions</title>
        <meta
          name="description"
          content="We know what it takes to ship smart digital products. And we know how to deliver CX-driven solutions that really work."
        />
      </Helmet>

      <header className={`${styles.solutionsHeader} padding-block-end-4`}>
        <div className="container">
          <h1 className="display-light">
            Digital <strong>solutions</strong>
          </h1>
          <h2 className="display-light margin-block-end-4">
            to human problems
            <i className={`dot ${styles.dotChanger}`}></i>
          </h2>
          <p className="display padding-block-end-4">
            Maybe you already know what you need in order to transform your
            relationship with your customers. Maybe you need us to help nail
            down a solid CX strategy first. Either way, we’ll develop the
            solution that makes the most sense for your business.
          </p>
        </div>
      </header>

      <article className={`padding-block-6 ${styles.hasBorder}`}>
        <ul className={styles.solutionsList}>
          <li className={styles.solution}>
            <DetailCard
              title="Value propositions"
              description="Distinguish yourself from your competition and get ahead in the market."
              url="/solutions/value-propositions"
              image={value}
              buttonText="MORE"
            ></DetailCard>
          </li>

          <li className={styles.solution}>
            <DetailCard
              title="Prototypes"
              description="Confirm market appetite and test your idea, quickly and accurately."
              url="/solutions/prototypes"
              image={prototypes}
              buttonText="MORE"
            ></DetailCard>
          </li>

          <li className={styles.solution}>
            <DetailCard
              title="Usability tests"
              description={`Great products are as usable as they are <i>useful</i>. Make sure yours are both.`}
              url="/solutions/ux-design"
              image={ux}
              buttonText="MORE"
            ></DetailCard>
          </li>

          <li className={styles.solution}>
            <DetailCard
              title="Web applications"
              description="Internet real-estate built from scratch, website overhauls, enhancements and more."
              url="/solutions/web-applications"
              image={web}
              buttonText="MORE"
            ></DetailCard>
          </li>

          <li className={styles.solution}>
            <DetailCard
              title="Mobile applications"
              description="Intuitive, purposeful apps designed and developed for Android and/or iPhone."
              url="/solutions/mobile-applications"
              image={mobile}
              buttonText="MORE"
            ></DetailCard>
          </li>

          <li className={styles.solution}>
            <DetailCard
              title="CX/Service design"
              description="Go beyond UX and ensure a great experience for your customers at every possible touchpoint."
              url="/solutions/cx-design"
              image={cx}
              buttonText="MORE"
            ></DetailCard>
          </li>
        </ul>
      </article>

      <br />

      {!!featured && (
        <article className="padding-block-start-6 padding-block-end-3 margin-block-end-6">
          <LargeArticleCard
            image={featured.featuredImage}
            title={featured.title}
            description={featured.snippet}
            url={`/work/case-study/${featured.slug}`}
            buttonText={featured.buttonText ?? 'Read More'}
          />
        </article>
      )}

      <article className="padding-block-6">
       
      </article>
    </div>
  );
}
