import React from 'react';
import { HeadlessCard, CardProps } from '../HeadlessCard';
import styles from './DetailCard.module.scss';

interface DetailCardProps extends CardProps {
  onClick?: () => void;
}

export function DetailCard(props: DetailCardProps): JSX.Element {
  return (
    <HeadlessCard {...props}>
      {({ image, title, description, buttonText, url }) => {
        if (Array.isArray(image)) image = image[0];

        const html = {
          __html: description,
        };

        return (
          <a href={url} onClick={props.onClick} className={styles.detailCard}>
            <div className={styles.cardImage}>
              <img loading="lazy" src={image} alt={title} />
            </div>
            <div className={styles.cardContent}>
              <h2 className="h1 margin-block-end-2">{title}</h2>
              <p
                className="display margin-block-end-4"
                dangerouslySetInnerHTML={html}
              ></p>
              <span className="anchor with-arrow margin-block-end-6">
                {buttonText}
              </span>
            </div>
          </a>
        );
      }}
    </HeadlessCard>
  );
}
