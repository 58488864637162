import React, { useEffect, useState } from 'react';
import styles from './LazyLoader.module.scss';
import loader from '../../assets/loader.svg';
import { useLocation } from 'react-router-dom';

function useSearchQuery(name: string, defaultValue?: string): string | null {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  return params.get(name) || defaultValue || null;
}

export function LazyLoader(props: {
  onNeedsLoad?: (done: () => void, complete?: () => void) => void;
  forceShow?: boolean;
  forceType?: string;
}): JSX.Element | null {
  const [show, setIsShown] = useState(!!props.forceShow);
  const [complete, setIsComplete] = useState(false);
  const test = useSearchQuery('loaderTestCase', props.forceType || 'a');

  useEffect(() => {
    const handler = () => {
      if (
        !complete &&
        // !show &&
        window.scrollY + 150 > document.body.scrollHeight - window.innerHeight
      ) {
        setIsShown(true);
        if (props && props.onNeedsLoad) {
          props.onNeedsLoad(
            () => {},
            () => {
              setIsShown(false);
              setIsComplete(true);
            }
          );
        }
      }
    };

    window.addEventListener('scroll', handler);

    return () => {
      window.removeEventListener('scroll', handler);
    };
  });

  if (show) {
    return (
      <div className={styles.loaderWrapper}>
        {test === 'b' && (
          <div className={styles.loader}>
            <div className={styles.logo}>
              <span></span>
              <span className={styles.dot}></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
            <div className={styles.label}>Loading More Awesome</div>
          </div>
        )}

        {test === 'a' && (
          <div className={styles.spinner}>
            <object data={loader} type="image/svg+xml">
              loader
            </object>
          </div>
        )}
      </div>
    );
  } else return null;
}
