import { createElement } from 'react';

export interface CardProps {
  image: string | [string, string, string];
  title: string;
  description: string;
  url: string;
  buttonText?: string;
}

interface Props extends CardProps {
  children: (args: CardProps) => JSX.Element;
}

export function createImageList(
  image: [string, string, string],
  alt?: string
): Array<JSX.Element> {
  const classList = ['mobile', 'tablet', 'desktop'];
  return image.map((src, key) =>
    createElement('img', {
      src,
      alt,
      className: classList[key],
      key,
      loading: 'lazy',
    })
  );
}

export function HeadlessCard(props: Props): JSX.Element | null {
  if (!props.children || typeof props.children !== 'function') return null;

  return props.children({
    image: props.image,
    title: props.title,
    description: props.description,
    url: props.url,
    buttonText: props.buttonText,
  });
}
