// Returns a number between the values
// passed in the parameters. If the
// "round" parameter is passed, the
// numbers can be left unrounded
function getRandomNumber([min, max]: [number, number], round: boolean = true) {
  if (round) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  } else {
    return Math.random() * (max - min + 1) + min;
  }
}

export { getRandomNumber };
