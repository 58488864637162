import React from 'react';
import styles from './Checkbox.module.scss';
import { InputRef } from '../../models/InputRef';
import { NestDataObject } from 'react-hook-form';

interface CheckboxProps {
  name: string;
  placeholder: string;
  checkType?: 'fill' | 'tick';
  theme?: 'blue' | 'yellow';
  value?: string;
  inputRef?: InputRef<HTMLInputElement>;
  onChecked?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  smaller?: boolean;
  errors?: NestDataObject<Record<string, any>>;
  bold?: boolean;
}

export function Checkbox(props: CheckboxProps): JSX.Element {
  const {
    name,
    placeholder,
    checkType = 'fill',
    theme = 'blue',
    errors,
  } = props;

  return (
    <div
      className={`${styles.inputWrapper} ${props.smaller &&
        styles.smallerInput} ${props.bold && styles.boldLabel} ${
        !!errors && !!errors[name] ? styles.fieldHasErrors : ''
      }`}
    >
      <input
        className={styles.checkboxInput}
        type="checkbox"
        name={name}
        id={name}
        value={props.value}
        tabIndex={0}
        ref={props.inputRef}
        onChange={props.onChecked}
      />
      <div
        className={`${styles.checkboxWrapper} ${styles[theme]}`}
        tabIndex={-1}
      >
        <div className={styles.checkbox} tabIndex={-1}>
          <span
            tabIndex={-1}
            className={`${styles[checkType]} ${styles[theme]}`}
          ></span>
        </div>
        <div tabIndex={-1} className={styles.checkboxLabel}>
          {placeholder}
        </div>
      </div>
    </div>
  );
}
