import useFetch from 'react-fetch-hook';
import { configs } from '../../../env';
import { CaseStudy } from '../../../models/case-study';

export function useFeaturedCaseStudy() {
  const { data, isLoading } = useFetch<CaseStudy>(
    `${configs.baseApiUrl}/CaseStudies/feature`
  );

  if (!!data) data.snippet = data.snippet.substring(0, 250);

  return { isLoading, featured: data };
}
