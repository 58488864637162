import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Checkbox, TextHeader } from '../../components';
import styles from './Legal.module.scss';
import tri from '../../assets/triangles.svg';
import { LegalNav } from '../../components/legal-nav/LegalNav';

const defaultValues = {
  perform: true,
  market: true,
};

export function ManageCookies(): JSX.Element {
  const [isDirty, setIsDirty] = useState(false);

  let values = JSON.parse(window.localStorage.getItem('cookiesSettings'));

  const { register, getValues, reset } = useForm({
    defaultValues: values ?? defaultValues,
  });

  function update() {
    setIsDirty(false);
    const value = JSON.stringify(getValues());
    window.localStorage.setItem('cookiesSettings', value);
    values = JSON.parse(value);
  }

  function cancel() {
    setIsDirty(false);
    reset(values ?? defaultValues);
  }

  return (
    <div className={styles.ManageCookies}>
      <ul>
        <li>
          <div className={`${styles.Content} ${styles.FullContent}`}>
            <h5>Strictly necessary</h5>
            <p>
              These cookies are needed to run our website, to keep it secure,
              and to obey regulations that apply to us.
            </p>
          </div>

          <div className={styles.Checkbox}>
            <p>Always on</p>
          </div>
        </li>

        <li>
          <div className={styles.Content}>
            <h5>Functional</h5>
            <p>
              These cookies remember your region or country, language,
              accessability options and your settings. This also includes things
              like your User ID on the logon page.
            </p>
          </div>

          <div className={styles.Checkbox}>
            <p>Always on</p>
          </div>
        </li>

        <li>
          <div className={styles.Content}>
            <h5>Performance</h5>
            <p>
              These cookies tell us how customers use our website. We study and
              profile this data to help us improve our products and performance.
            </p>
          </div>

          <div className={styles.Checkbox}>
            <Checkbox
              onChecked={() => setIsDirty(true)}
              inputRef={register}
              placeholder="Enable"
              name="perform"
            />
          </div>
        </li>

        <li>
          <div className={styles.Content}>
            <h5>Marketing</h5>
            <p>
              These cookies help us decide which products, services and offers
              may be relevant for you. We may use this data to tailor the ads
              you see on our own and other websites and apps.
            </p>
          </div>

          <div className={styles.Checkbox}>
            <Checkbox
              onChecked={() => setIsDirty(true)}
              inputRef={register}
              placeholder="Enable"
              name="market"
            />
          </div>
        </li>
      </ul>

      <div
        className={`${styles.ButtonActions} ${isDirty ? '' : styles.disabled}`}
      >
        <div className={styles.buttonInner}>
          <Button hasArrow={true} onClick={() => update()}>
            Confirm
          </Button>

          <div style={{ width: '15px' }}></div>

          <Button hasArrow={false} theme="light" onClick={() => cancel()}>
            Cancel
          </Button>
        </div>
      </div>

      <div className={styles.triangles}>
        <img loading="lazy" src={tri} alt="triangles" />
      </div>
    </div>
  );
}

export function ManageCookiePolicy(): JSX.Element {
  return (
    <div className={`${styles.policyPage} page`}>
      <TextHeader lineOne="Manage" lineTwo="Cookies"></TextHeader>
      <article>
        <section>
          <LegalNav></LegalNav>
        </section>

        <section>
          <ManageCookies />
        </section>
      </article>
    </div>
  );
}
